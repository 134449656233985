<template>
  <q-select
    :modelValue="inputText"
    use-input
    hide-selected
    fill-input
    input-debounce="0"
    :options="options"
    :option-label="toNationalId"
    @filter="filterFn"
    @input-value="setInputText"
    @update:modelValue="setValue"
    @blur="onBlur"
    :dropdownIcon="''"
    :hint="modelValue && modelValue.viewName"
    :error-message="errorMessage"
    :error="errorMessage != null"
    :readonly="readonly"
    :disable="readonly"
    :rules="innerRules"
    :lazy-rules="true"
    v-bind="$attrs"
    label="Person-/organisationsnummer"
    class="FindEntity"
    input-class="text-input"
    ref="input"
  >
    <template #option="scope">
      <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
        <q-item-section v-if="scope.opt">
          <q-item-label v-html="scope.opt.viewName" />
          <q-item-label caption>{{ formatNationalId(scope.opt) }} </q-item-label>
        </q-item-section>
      </q-item>
    </template>
  </q-select>
</template>
<script lang="ts">
// @ts-nocheck
import { Entity } from '@shared/web/models';
import { Component, Vue, Prop, Ref, Watch } from 'vue-facing-decorator';
import { isValidNationalId, formatNationalId, cleanNationalId } from '@shared/common';
import { isValidPersonalNumber } from '@shared/common';
import { lookupEntity, search } from '@shared/web/entity/entityService';
import { isBackOffice } from '@shared/web/utils';
@Component({})
export default class FindEntity extends Vue {
  @Ref() readonly input;
  @Prop() modelValue: Entity;
  @Prop({ default: () => [] }) entityOptions: Array<Entity>;
  @Prop() readonly: boolean;
  @Prop({ default: () => true }) useSearch: boolean;
  @Prop() optionalInputNationalId: string;
  @Prop() rules;
  errorMessage: string = null;
  inputText: string = null;
  options: Entity[] = [];
  isBO = isBackOffice();
  @Watch('modelValue', { immediate: true })
  onUpdatedValue(value) {
    if (this.optionalInputNationalId) {
      this.setInputText(this.optionalInputNationalId);
    } else if (value) {
      if (this.isBO && value.isGlobal === true) {
        this.setInputText(value.viewName.toLowerCase());
      } else this.inputText = formatNationalId(value, false);
    }
  }

  get disabled(): boolean {
    return this.optionalInputNationalId != null;
  }

  toNationalId(val) {
    if (val instanceof Entity) {
      return formatNationalId(val, false);
    }
    return val;
  }

  get innerRules() {
    return this.rules?.map(rule => {
      return () => rule.call(null, this.modelValue);
    });
  }

  filterFn(val, update) {
    update(() => {
      const needle = val.toLowerCase().replace('-', '');
      this.options = this.filterEntities(needle);
    });
  }

  filterEntities(needle) {
    return this.entityOptions
      .filter(entity => entity.viewName.toLowerCase().includes(needle) || entity.nationalId?.includes(needle))
      .slice(0, 10);
  }

  setValue(entity: Entity) {
    this.$emit('update:modelValue', entity);
  }

  onBlur() {
    if (!this.isMatchingNationalId() && !this.isBO) {
      this.setValue(null);
    }
  }

  isMatchingNationalId() {
    return this.modelValue && this.modelValue.nationalId === cleanNationalId(this.inputText);
  }

  validate() {
    return this.input.validate();
  }

  async setInputText(val: string) {
    this.inputText = val;

    if (isValidNationalId(val) && !this.isMatchingNationalId()) {
      await this.lookupEntity(val);
      return;
    }
    if (this.isBO) {
      await this.searchEntities(val);
    }
  }

  async lookupEntity(nationalId: string) {
    try {
      const entity = await lookupEntity({ nationalId, isPerson: isValidPersonalNumber(nationalId) });

      this.setValue(entity);

      this.input.resetValidation();
      this.errorMessage = null;
    } catch (error) {
      this.errorMessage = error.text;
    }
  }

  async searchEntities(nationalId: string) {
    try {
      const entities = await search({ query: nationalId, limit: 100 });

      const filteredEntities = entities.filter(entity => {
        if (entity.countryCode !== 'SE') {
          return entity.isGlobal;
        }
        return entity;
      });

      this.options = filteredEntities;

      this.input.resetValidation();
      this.errorMessage = null;
    } catch (error) {
      this.errorMessage = error.text;
    }
  }
}
</script>
<style lang="scss">
.FindEntity {
  .text-input {
    padding-bottom: 8px;
  }
}
</style>
