<template>
  <q-dialog ref="dialog" persistent @hide="onHide">
    <div class="q-dialog-plugin EditPositionModal test-position-modal" v-if="ready">
      <div class="position-model-header cursor-move q-pa-lg">
        <div v-if="isExecutedShareRegister">
          <h2 class="text-grey-5 text-weight-regular">Komplettera</h2>
          <h4 class="text-weight-light">Ändra kontaktuppgifter, förbehåll, samt aktiebrev utfärdat</h4>
        </div>
        <h2 v-else class="text-grey-5 text-weight-regular">{{ edit ? 'Ändra' : 'Lägg till' }} {{ label }}</h2>
        <q-btn class="closebtn" flat round size="sm" icon="close" @click="hide"></q-btn>
      </div>

      <div class="flex justify-between q-px-lg">
        <p class="p2">{{ instrumentName }}</p>

        <a-btn-link v-on:click="changeToForeignOwner" class="change icon-left" v-if="!positionIsPledge && !readonly">{{
          swedishOwner ? 'Byt till utländsk ' + label : 'Byt till svensk ' + label
        }}</a-btn-link>
      </div>

      <q-form ref="form" class="q-gutter-y-md q-mx-lg">
        <template v-if="!swedishOwner">
          <ForeignOwnerStatus
            v-if="!positionIsPledge"
            v-model="position.owner"
            :position="position"
            :shareRegister="shareRegister"
            :readonly="readonly"
          />
          <CompanyOwner
            v-model="position.owner"
            :entity="position.owner"
            :position="position"
            :contactInfo="ownerContactInfo"
            v-if="position.owner && position.owner.isLegalEntity"
            :readonly="readonly"
            :disable="positionIsPledge"
          />
          <PrivateOwner
            v-model="position.owner"
            :entity="position.owner"
            :contactInfo="ownerContactInfo"
            v-if="position.owner && position.owner.isPerson"
            :readonly="readonly"
            :disable="positionIsPledge"
          />
        </template>

        <template v-if="swedishOwner">
          <div class="section">
            <h5>{{ capitalize(label) }}</h5>
            <div class="row q-gutter-x-lg">
              <FindEntity
                class="col"
                v-model="position.owner"
                v-bind="$defs.input"
                :rules="validation.required('Ägare')"
                :entityOptions="swedishExistingOwners"
                :readonly="instructionIsPledge || instructionIsConvertPledge || readonly"
                :optionalInputNationalId="instructionIsPledge || instructionIsConvertPledge ? sellerNationalId : ''"
              />

              <q-input
                v-if="!isInsuranceCompany"
                class="col"
                v-bind="$defs.input"
                v-model="ownerContactInfo.email"
                hint="Frivillig uppgift"
                :rules="validation.email"
                label="E-post"
                lazy-rules
              ></q-input>
              <q-input
                v-if="!isInsuranceCompany"
                class="col"
                v-bind="$defs.input"
                v-model="ownerContactInfo.phone"
                hint="Frivillig uppgift"
                label="Telefon"
              ></q-input>
            </div>
            <q-checkbox v-model="showAddress" label="Lägg till/ Ta bort Adress"></q-checkbox>
            <div class="row q-gutter-x-lg" v-if="showAddress">
              <q-input
                v-if="!isInsuranceCompany"
                class="col"
                v-bind="$defs.input"
                v-model="ownerContactInfo.address.address1"
                hint="Frivillig uppgift"
                label="Adress"
              ></q-input>
              <q-input
                v-if="!isInsuranceCompany"
                class="col"
                v-bind="$defs.input"
                v-model="ownerContactInfo.address.address2"
                hint="Frivillig uppgift"
                label="C/O"
              ></q-input>
              <q-input
                v-if="!isInsuranceCompany"
                class="col"
                v-bind="$defs.input"
                v-model="ownerContactInfo.address.zip"
                hint="Frivillig uppgift"
                label="Postnummer"
              ></q-input>
              <q-input
                v-if="!isInsuranceCompany"
                class="col"
                v-bind="$defs.input"
                v-model="ownerContactInfo.address.city"
                hint="Frivillig uppgift"
                label="Stad"
              ></q-input>
            </div>

            <div v-if="isCompanyOwner && !isInsuranceCompany" class="row q-gutter-x-lg">
              <div class="col" />
              <q-input
                class="col"
                v-bind="$defs.input"
                v-model="ownerContactInfo.firstName"
                hint="Frivillig uppgift"
                label="Kontaktperson förnamn"
              />
              <q-input
                class="col"
                v-bind="$defs.input"
                v-model="ownerContactInfo.surName"
                hint="Frivillig uppgift"
                label="Kontaktperson efternamn"
              />
            </div>
          </div>
          <div class="section" v-if="position.owner && position.owner.isInsuranceCompany">
            <h5>Försäkringsägare</h5>
            <p class="B1Small">
              Detta är viktigt att fylla i eftersom du då kommer kunna se vilka som är investerare i ditt bolag oavsett
              hur de äger sina aktier.
            </p>
            <div class="row q-gutter-x-lg">
              <FindEntity
                class="col"
                v-model="position.investor"
                v-bind="$defs.input"
                :entityOptions="existingOwners"
                :readonly="readonly && !isBO"
              />
              <q-input
                class="col"
                v-bind="$defs.input"
                v-model="position.insuranceNumber"
                hint="Frivillig uppgift"
                square
                outlined
                label="Försäkringsnummer"
                lazy-rules
                :readonly="readonly && !isBO"
              ></q-input>
              <q-input
                class="col"
                v-bind="$defs.input"
                v-model="investorContactInfo.email"
                hint="Frivillig uppgift"
                square
                outlined
                label="E-post"
                lazy-rules
              ></q-input>
              <q-input
                class="col"
                v-bind="$defs.input"
                v-model="investorContactInfo.phone"
                hint="Frivillig uppgift"
                square
                outlined
                label="Telefon"
              ></q-input>
            </div>
          </div>
        </template>
        <div class="section" data-testid="position">
          <h5 v-if="isRights">Teckningsoptioner</h5>
          <h5 v-else-if="isConvertible">Konvertibel</h5>
          <h5 v-else-if="isBond">Obligation</h5>
          <h5 v-else-if="isShareholderContribution">Aktieägartillskott</h5>
          <h5 v-else>Aktiepost</h5>
          <div class="row flex q-gutter-x-md">
            <div class="col" v-if="tradeEventForm && isValueDistributed">
              <p class="B1Small-label">Belopp</p>
              <div class="flex">
                <a-input-number
                  class="col"
                  input-class="test-amount"
                  v-model="position.amount"
                  v-bind="$defs.input"
                  :readonly="readonly"
                />
              </div>
            </div>
            <div class="col" v-else-if="tradeEventForm">
              <p class="B1Small-label">Antal</p>
              <div class="flex">
                <a-input-number
                  class="col"
                  input-class="test-quantity"
                  v-model="position.quantity"
                  v-bind="$defs.input"
                  :readonly="readonly"
                />
              </div>
            </div>

            <CalculatePosition
              v-else
              :position="position"
              :defs="$defs"
              :shareNumberInput="shareNumberInput"
              :nextShareNumberTo="nextShareNumberTo"
              :numberSerieError="numberSerieError"
              :readonly="readonly"
            />

            <div class="col" v-if="isShare">
              <p class="B1Small-label">
                Förbehåll

                <a-info-btn>
                  <p>
                    För varje aktiepost finns det möjlighet att registrera eventuella förbehåll som finns. Detta
                    beroende på bolagsordning och annan dokumentation som bolaget har och som beskriver eventuella
                    förbehåll som finns, vilket kan vara hembud, förköp eller samtycke.
                  </p>
                  <p>
                    Om förbehåll registreras på aktieposten så kommer eventuella framtida ägarförändringar som
                    registreras i Kapclear behöva styrkas med dokumentation som visar att försäljning kan ske, enligt
                    dokumenterat förbehåll.
                  </p>
                </a-info-btn>
              </p>
              <a-checkbox-group v-model="position.reservations" :options="options.positionReservationsOptions" />
            </div>
            <div class="col" v-if="isShare">
              <p class="B1Small-label">Övrigt</p>
              <q-checkbox
                v-model="positionIsPledge"
                label="Aktiepost är pantsatt"
                data-testid="position-pledgeIssued"
                :disable="disablePledgeCheckbox"
              >
                <q-tooltip v-if="readonly">
                  {{ pledgeInfoText }}
                </q-tooltip>
              </q-checkbox>
              <q-checkbox
                v-model="position.certificateIssued"
                label="Aktiebrev är utfärdat"
                data-testid="position-certificateIssued"
              />
            </div>
            <div class="col">
              <p class="B1Small-label">Förvar</p>
              <SelectCustodian
                v-model="position.custodian"
                v-bind="$defs.input"
                :readonly="readonly && !isBO"
                :owner="position.owner"
              />
            </div>
            <div class="col custodian">
              <q-input
                v-model="position.custodianAccountNumber"
                v-bind="$defs.input"
                square
                outlined
                label="Depånummer"
                :disable="!position.custodian"
                :readonly="readonly && !isBO"
              ></q-input>
            </div>
          </div>
          <div class="row flex q-gutter-x-md q-mt-sm" v-if="positionIsPledge">
            <div class="col">
              <p class="B1Small-label">Pantägares person- eller orgnr</p>
              <FindEntity
                class="col"
                v-model="position.pledgeOwner"
                v-bind="$defs.input"
                :rules="validation.required('Pantägare')"
                :readonly="readonly"
              />
            </div>
            <div class="col">
              <p class="B1Small-label">Pantägares e-post</p>
              <q-input
                class="col test-email"
                v-bind="$defs.input"
                v-model="pledgeOwnerContactInfo.email"
                hint="Frivillig uppgift"
                square
                outlined
                label="E-post"
                lazy-rules
              ></q-input>
            </div>
            <div class="col">
              <p class="B1Small-label">Pantägares telefonnummer</p>
              <q-input
                class="col"
                v-bind="$defs.input"
                v-model="pledgeOwnerContactInfo.phone"
                hint="Frivillig uppgift"
                square
                outlined
                label="Telefon"
              ></q-input>
            </div>
          </div>
        </div>

        <div class="flex justify-end" v-if="errorMessage !== null">
          <p class="error-text">
            {{ errorMessage.text }}
          </p>
        </div>
        <div class="flex q-mb-lg footer-wrapper">
          <q-checkbox
            v-if="!edit && !tradeEventForm"
            v-model="keepDialog"
            class="keep-checkbox"
            label="Använd ovan uppgifter som mall för nästa registrering när den aktuella aktieägaren
            har fler positioner/poster. OBS bocka av boxen innan du registrerar sista positionen."
          />
          <div class="btn-wrapper flex row">
            <q-btn label="Avbryt" flat :loading="loading" @click="hide" class="cancelBtn q-mr-md" />
            <q-btn
              :label="[edit ? 'Spara ' : 'Lägg till '] + label"
              :disable="disableSave"
              :loading="loading"
              v-bind="$defs.btn"
              @click="save"
              class="save-btn"
            />
          </div>
        </div>
      </q-form>
    </div>
  </q-dialog>
</template>
<script lang="ts">
// @ts-nocheck
import { Component, Prop, Watch, Vue } from 'vue-facing-decorator';
import { nextTick } from 'vue';
import { DraggableComponent, DialogComponent, FormComponent } from '@shared/mixins/';
import {
  ShareRegister,
  Position,
  IssuerInfo,
  Instruction,
  InstructionParty,
  TransactionType,
  InstructionState,
} from '@shared/web/models';
import { Address } from '@shared/web/models';
import { findIssuerInfo, saveIssuerInfo } from '@shared/web/models/entity/issuerInfoService';
import { makeShareRegisterValidation } from '@shared/web/models/shareRegister/shareRegisterService';
import { options } from '@shared/common';
import { savePosition } from '@/models/position/positionService';
import FindEntity from './FindEntity.vue';
import SelectCustodian from '@/views/entity/SelectCustodian.vue';
import { saveEntity } from '@shared/web/entity/entityService';
import { updateWithSettlePosition } from '@shared/web/models/shareRegister/positionService';
import PrivateOwner from './PrivateOwner.vue';
import CompanyOwner from './CompanyOwner.vue';
import ForeignOwnerStatus from './ForeignOwnerStatus.vue';
import { validation } from '@shared/common';
import * as _ from 'lodash';
import { removeById } from '@shared/utils';
import { isBackOffice, makeReactive } from '@shared/web/utils';
import CalculatePosition from '@shared/views/editPositionModal/QuantityAndShareNumbers.vue';
import { getUniqueOwnersAndInvestors } from '@shared/shareRegister/utils';
import ConfirmWithDateModal from '@shared/components/ConfirmWithDateModal.vue';
@Component({
  components: {
    SelectCustodian,
    FindEntity,
    ForeignOwnerStatus,
    PrivateOwner,
    CompanyOwner,
    CalculatePosition,
  },

  mixins: [DialogComponent, FormComponent, DraggableComponent],
})
export default class EditPositionModal extends Vue {
  @Prop() shareRegister: ShareRegister;
  @Prop() instruction: Instruction;
  @Prop() value: Position;
  @Prop() edit: boolean;
  @Prop({ default: () => 'ägare' }) label: string;
  @Prop({ default: () => Position }) positionClass: any;
  @Prop({ default: () => false }) tradeEventForm: boolean;
  @Prop({ default: () => false }) readonly: boolean;
  @Prop({ default: { value: false } }) instructionDirty;

  issuerInfo: IssuerInfo = null;
  originalIssuerInfo: IssuerInfo = null;
  position: Position | InstructionParty;
  isCreate: boolean;
  swedishOwner: boolean;
  numberSerieError = null;
  keepDialog = false;
  pledgeIssued = false;
  isBO = isBackOffice();

  async created() {
    Object.assign(this, this.getData());
    this.issuerInfo = await findIssuerInfo(this.shareRegister.entity);
    this.originalIssuerInfo = this.issuerInfo.copy();
    await nextTick(() => {
      this.onShow();
    });
    this.position.shareNumberFrom = this.position.shareNumberFrom
      ? this.position.shareNumberFrom
      : this.nextShareNumberTo;
  }

  get showAddress() {
    return !!this.ownerContactInfo.address;
  }

  set showAddress(newValue) {
    if (newValue) {
      this.ownerContactInfo.address ??= new Address();
    } else {
      this.ownerContactInfo.address = null;
    }
  }

  @Watch('swedishOwner')
  onSwitchOwnerOrigin() {
    if (!this.ready) {
      return;
    }
    this.position.owner = null;
    this.issuerInfo = this.originalIssuerInfo.copy();
  }

  getData() {
    let position;
    if (this.value instanceof this.positionClass) {
      position = this.value.copy();
    } else {
      const data = Object.assign({}, this.value);
      position = this.positionClass.newModel(data);
    }
    const isCreate = position.id == null;
    return {
      position,
      isCreate,
      swedishOwner: position.owner == null || position.owner.isSE,
    };
  }

  get sellerNationalId(): string {
    return this.instruction?.tradeEvent?.source.owner?.nationalId;
  }

  get isValueDistributed() {
    if (this.instruction?.corporateEvent) {
      return this.instruction.corporateEvent?.rightsData?.isValueDistribution();
    }
    if (this.instruction?.tradeEvent) {
      return this.instruction.tradeEvent?.source?.instrument?.rightsData?.isValueDistribution();
    }

    return false;
  }

  get isExecutedShareRegister() {
    return (
      !this.tradeEventForm && this.shareRegister.entity.issuerData.shareRegisterState === InstructionState.EXECUTED
    );
  }

  get isRights() {
    return this.instruction?.type === TransactionType.ISSUE_WARRANT;
  }

  get isConvertible() {
    return this.instruction?.isConvertible;
  }

  get isBond() {
    return this.instruction?.isBond;
  }

  get isShareholderContribution() {
    return this.instruction?.isShareholderContribution;
  }

  get isShare() {
    if (this.position?.instrument) {
      return this.position.instrument.isShare;
    }
    return [TransactionType.ISSUE_SHARE, TransactionType.SPLIT].includes(this.instruction?.type);
  }

  get existingOwners() {
    return getUniqueOwnersAndInvestors(this.shareRegister.positions);
  }

  get swedishExistingOwners() {
    return this.existingOwners.filter(entity => entity.isSE);
  }

  get validation() {
    return validation;
  }

  get ready() {
    return this.issuerInfo != null;
  }

  get instrumentName() {
    return this.position.instrument?.viewName;
  }

  get options() {
    return options;
  }

  get instructionIsPledge() {
    return !!this.instruction?.isPledge;
  }

  get instructionIsConvertPledge() {
    return !!this.instruction?.isConvertPledge;
  }

  get positionIsPledge() {
    return this.instructionIsPledge || this.position.pledgeOwner != null || this.pledgeIssued;
  }

  set positionIsPledge(selected: boolean) {
    if (!selected) {
      this.position.pledgeOwner = null;
    }
    this.pledgeIssued = selected;
  }

  get pledgeInfoText(): string {
    return 'Pantsättning behöver göras via affärsbyte och samband med detta registrera pantsättningen samt vem som är panträttshavaren';
  }

  get disablePledgeCheckbox() {
    return !this.$route.meta.pledgeEditable;
  }

  get ownerContactInfo() {
    // @vue3 - hur reaktivitet fungerar har ändrats. Computed properties är inte längre reaktiva per automatik
    return makeReactive(this.issuerInfo.getContactInfo(this.position.owner));
  }

  get investorContactInfo() {
    return makeReactive(this.issuerInfo.getContactInfo(this.position.investor));
  }

  get pledgeOwnerContactInfo() {
    return makeReactive(this.issuerInfo.getContactInfo(this.position.pledgeOwner));
  }

  get isCompanyOwner() {
    return this.position.owner && !this.position.owner.isPerson;
  }

  get isInsuranceCompany(): boolean {
    return !!this.position?.owner?.isInsuranceCompany;
  }
  get nextShareNumberTo() {
    let lastPositionShareNumberTo =
      this.shareRegister.positions[this.shareRegister.positions.length - 1]?.shareNumberTo + 1;
    return lastPositionShareNumberTo || 1;
  }

  capitalize(string) {
    return _.capitalize(string);
  }

  shareNumberInput() {
    if (!(this.position instanceof Position)) {
      return;
    }
    this.numberSerieError = null;
    if (this.position.quantity && this.position.shareNumberFrom) {
      this.position.shareNumberTo = this.position.quantity + this.position.shareNumberFrom - 1;
    }
  }

  changeToForeignOwner() {
    if (!this.positionIsPledge) {
      this.position.owner = null;
    }

    this.issuerInfo = this.originalIssuerInfo.copy();
    this.swedishOwner = !this.swedishOwner;
  }

  get positionValidation() {
    if (!(this.position instanceof Position)) {
      return null;
    }

    const positionIndex = _.indexOf(this.shareRegister.positions, this.value);
    const positions = [...this.shareRegister.positions];
    if (positionIndex !== -1) {
      positions.splice(positionIndex, 1);
    }
    positions.push(this.position);
    const totals = makeShareRegisterValidation(this.shareRegister, positions);
    return totals.forPosition(this.position);
  }

  saveInstructionParty() {
    const { destinations } = this.instruction;
    const existingIndex = _.findIndex(destinations, dest => dest === this.value);
    if (existingIndex < 0) {
      destinations.push(this.position);
    } else {
      destinations.splice(existingIndex, 1, this.position);
    }

    return this.position;
  }

  async savePosition(position: Position) {
    try {
      const { model } = await savePosition(position);
      this.shareRegister.addPosition(model);
      return model;
    } catch (error) {
      if (error.key === 'shareRegister.locked') {
        return this.savePositionWithTransaction(this.position);
      } else {
        throw error;
      }
    }
  }

  async savePositionWithTransaction(position) {
    return new Promise((resolve, reject) => {
      this.$q.dialog({
        component: ConfirmWithDateModal,
        componentProps: {
          confirmText: 'Förändringen kommer resultera i en ny position och kräver datum för införd i aktieboken',
          dateLabel: 'Inför i aktieboken',
          onConfirm: async settleDate => {
            const newPosition = await updateWithSettlePosition(position, settleDate);
            removeById(this.shareRegister.positions, position);
            this.shareRegister.addPosition(newPosition);
            resolve(newPosition);
          },
          onReject: () => {
            reject();
          },
        },
      });
    });
  }

  async save() {
    const result = await this.submitForm(async () => {
      if (!this.tradeEventForm) {
        this.numberSerieError = this.positionValidation.numberSerieError?.text;
        if (this.numberSerieError) {
          return false;
        }
      }

      if (!this.swedishOwner) {
        const contactInfo = this.ownerContactInfo;
        const { owner } = this.position;
        if (!this.position.owner.isPerson) {
          const { investorManager } = this.position.owner.legalEntity;
          if (investorManager && investorManager.countryCode && investorManager.viewName) {
            investorManager.creator = this.shareRegister.entity;
            this.position.owner.legalEntity.investorManager = await saveEntity(investorManager);
          } else {
            this.position.owner.legalEntity.investorManager = null;
          }
        }
        if (!owner.isGlobal) {
          owner.creator = this.shareRegister.entity;
          this.position.owner = await saveEntity(owner);
        }
        contactInfo.entity = this.position.owner;
      }

      this.issuerInfo = await saveIssuerInfo(this.issuerInfo);

      if (this.positionIsPledge === false) {
        this.position.pledgeOwner = null;
      }

      if (this.position instanceof Position) {
        return await this.savePosition(this.position);
      } else {
        return this.saveInstructionParty();
      }
    });

    if (result) {
      this.$emit('ok', result);
      if (this.keepDialog) {
        if (result instanceof Position) {
          this.position = this.resetPosition(result);
        } else if (this.position instanceof InstructionParty) {
          this.position = this.resetInstructionParty(this.position);
        }
      } else {
        this.hide();
      }
    }
  }

  resetPosition(position: Position) {
    const result = position.copy();
    result.reservations = [];
    result.certificateIssued = null;
    result.shareNumberFrom = null;
    result.shareNumberTo = null;
    result.quantity = null;
    result.custodian = null;
    result.custodianAccountNumber = null;
    result.id = null;
    return result;
  }

  resetInstructionParty(instructionParty: InstructionParty) {
    const result = instructionParty.copy();
    result.reservations = [];
    result.quantity = null;
    result.custodian = null;
    result.custodianAccountNumber = null;
    result.id = null;
    return result;
  }

  get attributes() {
    return {
      input: {
        dense: true,
        filled: true,
        square: true,
      },
    };
  }
}
</script>
<style lang="scss">
.EditPositionModal {
  position: absolute;
  &:not(.step1) {
    width: 900px;
    max-width: 900px;
    background-color: white;
  }

  .section {
    div.row ~ div.row {
      margin-top: 8px;
    }
  }

  .cursor-move {
    cursor: move;
  }
  .save-btn {
    margin: auto;
  }

  .position-model-header {
    justify-content: space-between;
    display: flex;
  }

  .a-btn-link {
    // @vue3 varför är a-btn-link satt som display table? Det borde vara standard inline-flex.
    display: inline-flex !important;
    &.icon-left {
      padding-left: 20px;
      padding-top: 5px;
    }
    &:before,
    &:after {
      top: calc(50% - 10px);
    }
  }

  .cancelBtn {
    font-size: 14px;
    color: $primary;
  }
  .p2 {
    font-size: 18px;
    color: $grey-6;
    margin: 1px 0 12px;
  }
  .p3 {
    color: $secondary;
    font-size: 12px;
    padding-top: 4px;
    cursor: pointer;
  }

  .CheckBoxGroup {
    display: flex;
    flex-wrap: wrap;
    margin-left: 3px;
    & > div {
      flex: 0 0 50%;
      margin-left: 0;
    }
  }

  .q-checkbox__label {
    line-height: 100%;
    font-size: 14px;
  }

  .btn-wrapper {
    margin-left: auto;
  }

  .footer-wrapper {
    flex-flow: wrap;
  }

  .keep-checkbox {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .number-serie-input .q-field__bottom {
    width: 200px;
  }

  .q-form {
    .section {
      background-color: $grey-1;
      padding: 16px;
    }
    .custodian {
      padding-top: 30px;
    }
    h5 {
      padding-bottom: 8px;
      font-size: 16px;
    }
    .col-3 > *:first-child:not(h5) {
      padding-top: 41px;
    }
    p {
      margin: 0 0 8px;
    }
    .B1Small-label {
      margin: 0 0 4px;
    }
    .radiobtn {
      margin-left: -10px;
      .q-radio {
        height: 32px;
      }
    }
  }
}
</style>
