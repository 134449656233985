<template>
  <q-page v-if="ready" class="ShareRegisterPage">
    <div class="row justify-between">
      <h1>Skapa aktiebok</h1>
      <LastEntityUpdate />
    </div>
    <Stepper pageType="shareRegister" :currentstep="1" :isDone="isDone" v-if="isEditable" :urlId="urlId" />

    <CreateShareRegisterInfo :shareRegister="shareRegister" />
    <q-separator />
    <SharesInfo :shareRegister="shareRegister" :instruction="instruction" :readonly="!isEditable" @change="save" />
    <h4 class="table-header">Fördela Aktier</h4>
    <ShareRegisterView :shareRegister="shareRegister" :readonly="!isEditable" stage="CREATE" />
    <q-btn
      v-bind="$defs.btn"
      type="button"
      :disable="!isDone"
      class="step-btn"
      @click="proceed"
      v-if="isEditable"
      label="Gå vidare"
    />
  </q-page>
</template>
<script lang="ts">
import { Component, Vue, Watch } from 'vue-facing-decorator';
import { ShareRegister, InstructionState, Instruction, TransactionType } from '@/models';
import SharesInfo from '@shared/views/shareRegister/SharesInfo.vue';
import ShareRegisterView from '@shared/views/shareRegister/ShareRegisterView.vue';
import Stepper from '@/components/Stepper.vue';
import LastEntityUpdate from '@shared/components/LastEntityUpdate.vue';
import {
  findShareRegisterByEntityId,
  makeShareRegisterValidation,
} from '@shared/web/models/shareRegister/shareRegisterService';
import CreateShareRegisterInfo from '@/views/dashboard/CreateShareRegisterInfo.vue';
import { makeNewInstruction, saveInstruction } from '@/models/instruction/instructionService';
import { findEntityWithRouteParams } from '@shared/web/entity/entityService';
import { isToNewPage } from '@/utils';
@Component({
  components: { SharesInfo, ShareRegisterView, Stepper, LastEntityUpdate, CreateShareRegisterInfo },
})
export default class CreateShareRegisterPageView extends Vue {
  shareRegister: ShareRegister = null;
  instruction: Instruction = null;

  async created() {
    await this.loadData();
  }

  get isEditable() {
    const state = this.shareRegister.entity.issuerData.shareRegisterState;
    return state === InstructionState.NEW || state === InstructionState.REQUEST_CHANGE;
  }
  get urlId() {
    return this.shareRegister.entity.countryCode + '/' + this.shareRegister.entity.nationalId;
  }
  @Watch('shareRegister.positions')
  async updateEntity() {
    await findEntityWithRouteParams(this.$route.params);
  }
  @Watch('$route')
  async loadData(to?, from?) {
    if (isToNewPage(to, from)) {
      return;
    }
    const entity = await findEntityWithRouteParams(this.$route.params);
    this.shareRegister = await findShareRegisterByEntityId(entity);

    this.instruction = await makeNewInstruction({
      entity: this.shareRegister.entity,
      type: TransactionType.SHAREREGISTER_CREATED,
    });
  }

  async save() {
    await saveInstruction(this.instruction);
  }

  get ready() {
    return this.shareRegister != null && this.instruction != null;
  }

  get shareRegisterValidation() {
    return makeShareRegisterValidation(
      this.shareRegister,
      this.shareRegister.positions,
      this.instruction.skipIssuerDataValidation,
    );
  }

  get isDone() {
    return this.shareRegisterValidation.isValid();
  }

  proceed() {
    this.$router.push({
      path: `${this.shareRegister.entity.baseUrl}/skapa-aktiebok/dokument`,
    });
  }
}
</script>
<style lang="scss">
.ShareRegisterPage {
  .q-item__section--avatar {
    min-width: auto;
  }
}
</style>
