<template>
  <tr class="holder-row">
    <td colspan="5">
      <router-link :to="instrument.viewUrl" v-if="isBO">
        {{ instrument.viewName }}
      </router-link>
      <router-link :to="instrument.issueInstructionUrl" v-else-if="instrument.issueInstructionUrl">
        {{ instrument.viewName }}
      </router-link>
      <template v-else>{{ instrument.viewName }}</template>
    </td>

    <q-td class="text-right">
      {{ formatNumber(instrument.rightsData.nominalAmountTotal) }} {{ instrument.rightsData.currency }}
    </q-td>
  </tr>
</template>

<script lang="ts">
import { Instrument } from '@shared/web/models';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { isBackOffice } from '@shared/web/utils';

@Component({})
export default class InstrumentRow extends Vue {
  @Prop() instrument: Instrument;

  get isBO() {
    return isBackOffice();
  }
}
</script>
