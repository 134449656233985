<template>
  <q-page v-if="ready" class="DocumentPage">
    <h1>{{ instruction.processTitle }}</h1>
    <Stepper :pageType="instruction.type" :currentstep="2" :urlId="instruction.id" />
    <q-separator />
    <div v-if="instruction && instruction.isConvertible">
      <p class="p2">
        Bifoga ett eller flera signerade dokument i PDF-format som verifierar konvertibelprogrammet <br />
        <span class="text-small">(Bifogat dokument motsvarande det som skickades in till bolagsverket)</span>
      </p>
    </div>
    <div v-if="instruction && instruction.isBond">
      <p class="p2">
        Bifoga ett eller flera signerade dokument i PDF-format som verifierar obligationsprogrammet <br />
        <span class="text-small">(Bifogat dokument motsvarande det som skickades in till bolagsverket)</span>
      </p>
    </div>
    <div v-if="instruction && instruction.isDebenture">
      <p class="p2">
        Bifoga ett eller flera signerade dokument i PDF-format som verifierar vinstandelslånet <br />
        <span class="text-small">(Bifogat dokument motsvarande det som skickades in till bolagsverket)</span>
      </p>
    </div>
    <div v-if="instruction && instruction.isWarrant">
      <h4>Verifiera teckningsoptionsprogram</h4>
      <p class="p2">
        Bifoga ett eller flera signerade dokument i PDF-format som verifierar teckningsoptionsprogrammet <br />
        <span class="text-small">(Bifogat dokument motsvarande det som skickades in till bolagsverket)</span>
      </p>
    </div>
    <div v-if="instruction && instruction.isSupplement">
      <h4>Verifiera komplettering</h4>
      <p class="p2">
        Bifoga ett eller flera signerade dokument i PDF-format som verifierar kompletteringen <br />
        <span class="text-small">(Bifogat dokument motsvarande det som skickades in till bolagsverket)</span>
      </p>
    </div>
    <q-separator />

    <UploadFileButton @click="uploadDocuments" v-if="canEdit" class="q-mt-lg" />
    <InputFile v-if="canEdit" :instruction="instruction" ref="inputFile" />
    <FileList class="q-mt-md" :parent="instruction" />
    <q-btn v-bind="$defs.btn" type="button" class="step-btn" :disable="!isDone || !canEdit" @click="proceed"
      >Gå vidare</q-btn
    >
    <a-btn-link v-on:click="goBack" class="arrow icon-left">Gå tillbaka</a-btn-link>
  </q-page>
</template>
<script lang="ts">
import { Component, Vue, Watch } from 'vue-facing-decorator';
import { ShareRegister, Instruction } from '@/models';
import Stepper from '@/components/Stepper.vue';
import FileList from '@/views/file/FileList.vue';
import UploadFileButton from '@/views/file/UploadFileButton.vue';
import InputFile from '@/components/InputFile.vue';
import { findInstruction } from '@/models/instruction/instructionService';
import { store } from '@shared/web/store';
import { isToNewPage } from '@/utils';

@Component({
  components: { Stepper, FileList, InputFile, UploadFileButton },
})
export default class InstructionDocumentsPage extends Vue {
  shareRegister: ShareRegister = null;
  instruction: Instruction = null;

  get canEdit() {
    return store.entityAdmin?.limitedAccess === false;
  }

  async created() {
    await this.loadData();
  }
  @Watch('$route')
  async loadData(to?, from?) {
    if (isToNewPage(to, from)) {
      return;
    }
    const { id } = this.$route.params;
    const { shareRegister, instruction } = await findInstruction(id);
    Object.assign(this, { shareRegister, instruction });
  }
  uploadDocuments() {
    const component = this.$refs.inputFile as any;
    component.input.click();
  }
  get ready() {
    return this.shareRegister != null && this.instruction != null;
  }
  get isDone() {
    return this.instruction.hasFiles;
  }
  proceed() {
    this.$router.push({
      path: `${this.instruction.entity.baseUrl}/${this.instruction.uriLabel}/${this.instruction.id}/granska`,
    });
  }
  goBack() {
    this.$router.push({
      path: `${this.instruction.entity.baseUrl}/${this.instruction.uriLabel}/${this.instruction.id}`,
    });
  }
}
</script>
