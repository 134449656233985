<template>
  <div class="TransactionsDetails">
    <div class="flex row q-gutter-x-lg">
      <div class="col-1">
        <p class="B1Small-label">Teckningskurs</p>
        <a-input-number
          v-bind="$defs.input"
          v-model="instruction.price"
          :readonly="readonly"
          @change="change"
          :rules="priceRules"
          :maximumFractionDigits="6"
          :minimumFractionDigits="2"
          input-class="text-right test-price"
        ></a-input-number>
      </div>
      <div class="col-2">
        <p class="B1Small-label">Avstämningsdag</p>
        <a-input-date
          v-bind="$defs.input"
          placeholder="yyyy-mm-dd"
          v-model="instruction.corporateEvent.recordDate"
          @change="change"
          :readonly="readonly"
          :rules="recordDateRules"
        ></a-input-date>
      </div>
      <div class="col-2">
        <p class="B1Small-label">
          Införd i aktieboken
          <a-info-btn>
            <p>
              Införd i aktieboken är den dagen deltagare i nyemissionen kommer bli införda i aktieboken. Den dagen man
              anger som införd i aktieboken bör då också vara samma dag som betalningsdag (likviddag). Dvs den dagen
              deltagarna i emissionen har betalat in emissionslikviden. Införd i aktieboken som registreras behöver
              alltså vara idag eller tidigare, den kan inte vara framåt i tiden.
            </p>
          </a-info-btn>
        </p>
        <a-input-date
          v-bind="$defs.input"
          placeholder="yyyy-mm-dd"
          v-model="instruction.settleDate"
          @change="change"
          :readonly="readonly"
          :rules="settleDateRules"
        ></a-input-date>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { Instruction, ShareRegister } from '@/models';
import { requiredNumber } from '@shared/utils/validation';

@Component({
  components: {},
})
export default class TransactionsDetails extends Vue {
  @Prop() shareRegister: ShareRegister;
  @Prop() instruction: Instruction;
  @Prop({ default: () => false }) readonly: boolean;
  @Prop() validator;

  change() {
    this.$emit('change');
  }

  get issuerData() {
    return this.shareRegister.entity.issuerData;
  }

  get recordDateRules() {
    return [() => this.validator.invalidCorporateEventDates()?.text];
  }

  get settleDateRules() {
    return [() => this.validator.invalidSettleDate()?.text];
  }

  get priceRules() {
    return [requiredNumber];
  }
}
</script>
<style lang="scss">
.TransactionsDetails {
  margin-bottom: -22px;
}
</style>
