import { modelHandler, serialize } from '../base';
import { Position } from '@shared/web/models';
import { removeById } from '@shared/utils';
import { findEntity } from '@shared/web/entity/entityService';
import { HistoryEntry } from '@shared/web/models/shareRegister/PositionHistory';
import axios from 'axios';

const baseUrl = '/webapi/position';

const { saveModel, destroyModel } = modelHandler(Position, baseUrl);

export async function savePosition(position: Position) {
  const result = await saveModel(position);
  await findEntity(result.model.entity);
  return result;
}

export async function deletePosition(position: Position, shareRegister) {
  await destroyModel(position);
  removeById(shareRegister.positions, position);

  await findEntity(position.entity);
}

export async function loadPositionHistory(position: Position): Promise<Array<HistoryEntry>> {
  const { data } = await axios.get(`${baseUrl}/history/${position.id}`);
  return HistoryEntry.newModels(data);
}

export async function savePositionHistory(history: Array<HistoryEntry>) {
  const historyData = history.map(pos => serialize(pos));
  await axios.post(`${baseUrl}/history`, historyData);
}
