<template>
  <tr :class="['DestinationRow', supplementRowClass]">
    <td v-if="isRightsIssue"></td>
    <td class="text-left wrap-text vertical-middle">
      <p>{{ destination.owner?.viewName }}</p>
      <p class="nationalid-small">{{ formatNationalId(destination.owner) }}</p>
    </td>
    <td class="text-left wrap-text vertical-middle">
      <p>{{ destination.investor && destination.investor.viewName }}</p>
      <p class="nationalid-small">{{ formatNationalId(destination.investor) }}</p>
    </td>
    <td class="text-left vertical-middle">{{ destination.custodianName }}</td>
    <td class="text-right vertical-middle" v-if="isRightsIssue">{{ formatNumber(ownedQuantity) }}</td>
    <td v-if="isExerciseWarrant" class="text-right">{{ formatNumber(originalQuantity) }}</td>
    <td class="text-right new-share" v-if="isRightsIssue">{{ formatNumber(allocatedQuantity) }}</td>
    <td class="text-right" v-if="!isValueDistributed">
      <a-input-number
        v-bind="$defs.filledInput"
        input-class="text-right destination-issued"
        v-model="destination.quantity"
        :fractionDigits="0"
        @update:modelValue="handleQuantityChange"
        :readonly="!isQuantityChangingAllowed"
      />
      <PositionAdditionalInformationOnRowItem :position="destination" :useMargin="true" />
    </td>
    <td class="text-right" v-if="isConvertible || isBond || isValueDistributed">
      <a-input-number
        :disable="!isValueDistributed || readonly"
        input-class="text-right destination-issued"
        v-model="destination.amount"
        @update:modelValue="$emit('change')"
        v-bind="$defs.input"
      />
    </td>
    <td class="text-right vertical-middle" v-if="isTrade">{{ formatNumber(totalAmount) }}</td>
    <td v-if="isExerciseWarrant" class="text-right vertical-middle">
      {{ formatNumber(destination.quantity * contractSize) }}
    </td>

    <td class="q-table--col-auto-width more-menu test-destination" id="test-more-menu">
      <q-btn
        flat
        round
        color="grey-4"
        icon="more_vert"
        size="sm"
        v-if="!readonly"
        :data-testid="`action-button-${destination.id}`"
      >
        <q-menu self="center left" anchor="center left" :offset="[70, 0]" class="a-tooltip shadow-1">
          <q-list>
            <q-item clickable v-close-popup @click="editPosition" :data-testid="`action-button-edit-${destination.id}`">
              <q-item-section class="test-edit-destination">Ändra</q-item-section>
            </q-item>
            <q-item clickable v-close-popup @click="removePosition">
              <q-item-section icon="delete">Ta bort</q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </q-btn>
    </td>
  </tr>
</template>
<script lang="ts">
import { Instruction, InstructionParty, InstructionState, ShareRegister, TransactionType } from '@/models';
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { validation } from '@shared/common';
import EditPositionModal from '@shared/views/editPositionModal/EditPositionModal.vue';
import RemovePositionModal from '@shared/views/editPositionModal/RemovePositionModal.vue';
import PositionAdditionalInformationOnRowItem from '@shared/components/PositionAdditionalInformationOnRowItem.vue';

@Component({ components: { PositionAdditionalInformationOnRowItem } })
export default class DestinationRow extends Vue {
  @Prop() destination: InstructionParty;
  @Prop() shareRegister: ShareRegister;
  @Prop() instruction: Instruction;
  @Prop() validator;
  @Prop({ default: () => false }) readonly: boolean;

  get isExerciseWarrant() {
    return this.instruction.type === TransactionType.EXERCISE_WARRANT;
  }

  get supplementRowClass(): string {
    return this.instruction.rowIsModified(this.destination)
      ? 'row-is-modified'
      : this.instruction.newRowAdded(this.shareRegister, this.destination)
      ? 'new-row-added'
      : '';
  }

  get totalAmount() {
    if (this.isValueDistributed) {
      return (this.instruction.price / this.instruction.tradeEvent.source.amount) * this.destination.amount;
    }
    return this.destination.quantity * this.instruction.price;
  }

  get originalQuantity() {
    return this.destination.allocatedQuantity;
  }

  get isRightsIssue() {
    return this.instruction.type === TransactionType.RIGHTS_ISSUE;
  }

  get contractSize() {
    if (this.isExerciseWarrant) {
      return this.rightsData.contractSize;
    }
    return this.rightsData.contractSize;
  }

  get validation() {
    return validation;
  }

  get isTrade() {
    return this.instruction.isTradeEvent;
  }

  get isConvertible() {
    return this.instruction.isConvertible;
  }

  get isBond() {
    return this.instruction.isBond;
  }

  get isValueDistributed() {
    if (this.isTrade) {
      return this.instruction?.tradeEvent?.source?.instrument?.rightsData?.isValueDistribution();
    }
    return this.instruction?.corporateEvent?.rightsData?.isValueDistribution();
  }

  get isQuantityChangingAllowed() {
    if (this.instruction.type === TransactionType.EXERCISE_WARRANT) {
      return [InstructionState.NEW, InstructionState.REQUEST_CHANGE].includes(this.instruction.state);
    }
    return !this.readonly;
  }

  get partyValidation() {
    if (!this.validator) {
      return null;
    }
    return this.validator?.forParty(this.destination);
  }

  get ownedQuantity() {
    return this.partyValidation?.totalQuantity;
  }

  get allocatedQuantity() {
    if (this.instruction.type === TransactionType.EXERCISE_WARRANT) {
      return this.destination.allocatedQuantity;
    }

    if (this.sourceValidation.ratio == null) {
      return null;
    }
    return this.ownedQuantity / this.sourceValidation.ratio;
  }

  get sourceValidation() {
    return this.validator.forInstrumentSource(this.destination.instrument);
  }

  get rightsData() {
    return this.instruction.corporateEvent.rightsData;
  }

  handleQuantityChange(value) {
    if (this.isExerciseWarrant && value > this.originalQuantity) {
      this.destination.quantity = this.originalQuantity;
    }
    this.$emit('change');
  }

  editPosition() {
    this.$q
      .dialog({
        component: EditPositionModal,
        componentProps: {
          positionClass: InstructionParty,
          value: this.destination,
          instruction: this.instruction,
          edit: true,
          label: 'deltagare',
          tradeEventForm: true,
          shareRegister: this.shareRegister,
        },
      })
      .onOk(() => {
        this.$emit('change');
      });
  }
  removePosition() {
    this.$q.dialog({
      component: RemovePositionModal,
      componentProps: {
        instruction: this.instruction,
        value: this.destination,
        shareRegister: this.shareRegister,
      },
    });
  }
}
</script>
<style lang="scss">
tr.DestinationRow {
  &.row-is-modified {
    background-color: #ffc795;
  }
  &.new-row-added {
    background-color: #ccffaf;
  }
}
</style>
