import { Instruction, InstructionParty } from '@shared/web/models';
import { remove } from '@shared/utils';
import { modelHandler } from '../base';

const baseUrl = '/webapi/instruction';
const { saveModel } = modelHandler(Instruction, baseUrl);

export async function saveInstruction(instruction: Instruction) {
  const result = await saveModel(instruction);

  return result;
}

export function removeDestination(instruction: Instruction, destination: InstructionParty) {
  destination.deleted = true;
  if (destination.id == null) {
    remove(instruction.destinations, destination);
  }
}
