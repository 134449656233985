import { Model } from '../base';
import { Entity, Role } from '@shared/web/models';
import { User } from '@shared/web/models';
import { EntityAdminRole } from '@shared/models/types';

export default class EntityAdmin extends Model {
  email: string;
  phone: string;
  limitedAccess: boolean;
  role: EntityAdminRole;
  connectedEntityRole: Role;
  canLoginWithEmail: boolean;
  entity: Entity;
  user: User;
  wantEmails: boolean | null;

  constructor(attr = {}) {
    super(attr);
    Object.assign(this, attr);
  }

  static define = () => ({
    user: {
      model: User,
    },
    entity: {
      model: Entity,
    },
    connectedEntityRole: {
      model: Role,
    },
  });
}
