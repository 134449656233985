import { Model } from '../base';
import { Entity, Instrument, Ownership, Position } from '@shared/web/models';
import { IInstructionParty } from '@shared/models/modelTypes';

export default class InstructionParty extends Model implements IInstructionParty {
  owner: Entity = null;
  investor: Entity = null;
  insuranceNumber: string = null;
  quantity: number = null;
  entity: Entity = null;
  instrument: Instrument = null;
  custodian: Entity = null;
  custodianAccountNumber = null;
  pledgeOwner: Entity = null;
  allocatedQuantity?: number = null;
  reservations: Array<string> = [];
  certificateIssued = false;
  ownership: Ownership;
  settleFrom?: Date;

  /**
   * nom. belopp vid konvertibler
   */
  amount?: number = null;
  origin?: Position = null;
  deleted? = false;
  static define = () => ({
    owner: {
      model: Entity,
    },
    investor: {
      model: Entity,
    },
    entity: {
      model: Entity,
    },
    instrument: {
      model: Instrument,
    },
    custodian: {
      model: Entity,
    },
    pledgeOwner: {
      model: Entity,
    },
    origin: {
      model: Position,
    },
    ownership: {
      model: Ownership,
    },
    position: {
      model: Position,
    },
    settleFrom: {
      type: 'date',
    },
  });

  get absoluteInvestor() {
    return this.investor || this.owner;
  }
  get absoluteInvestorManager() {
    const investor = this.absoluteInvestor;
    if (investor && investor.isLegalEntity && investor.legalEntity.investorManager) {
      return investor.legalEntity.investorManager;
    }
    return investor;
  }

  get custodianName() {
    return this.custodian?.viewName || 'Kapclear';
  }

  get pledgeIssued(): boolean {
    return !!this.pledgeOwner;
  }
}
