<template>
  <q-page class="relative-position">
    <div v-if="ready">
      <div class="row items-center justify-between">
        <h1 class="q-my-none">{{ instruction.processTitle }}</h1>
        <div v-if="validForSupplement && instruction.executed">
          <q-btn @click="newSupplementInstruction">Komplettera deltagare</q-btn>
        </div>
      </div>
      <div class="q-mb-xl">
        <Stepper :pageType="instruction.type" :currentstep="1" :urlId="instruction.id" v-if="isInstructionEditable" />
      </div>
      <div
        class="q-mb-xl"
        v-if="instruction.state === InstructionState.REQUEST_CHANGE && instruction.requestedChangeMessage"
      >
        <q-banner
          v-if="instruction.state === InstructionState.REQUEST_CHANGE && instruction.requestedChangeMessage"
          class="q-mt-md bg-orange-1 bg-orange-2"
        >
          Emissionen behöver kompletteras.<br />
          Kommentar: {{ instruction.requestedChangeMessage }}
        </q-banner>
      </div>
      <div class="q-mb-xl" v-if="!instruction.isSupplement">
        <ConvertibleDetails
          v-if="instruction.isConvertible"
          :instruction="instruction"
          :entity="instruction.entity"
          :readonly="!isDataEditable"
          @change="handleDetailsChange"
          @input="handleDetailsChange"
        />
        <BondDetails
          v-if="instruction.isBond"
          :instruction="instruction"
          :readonly="!isDataEditable"
          @change="handleDetailsChange"
          @input="handleDetailsChange"
        />
        <DebentureDetails
          v-if="instruction.isDebenture"
          :instruction="instruction"
          :readonly="!isDataEditable"
          @change="handleDetailsChange"
          @input="handleDetailsChange"
        />
        <ShareholderContributionDetails
          v-if="instruction.isShareholderContribution"
          :instruction="instruction"
          :readonly="!isDataEditable"
          :destinationsAmount="destinationsAmount"
          @change="handleDetailsChange"
          @input="handleDetailsChange"
        />
      </div>
      <DestinationsTableCard
        :shareRegister="shareRegister"
        :instruction="instruction"
        :readonly="!isDataEditable"
        :disableAdd="!isDataEditable || hasUnsavedChanges"
        @change="handleDestinationsChange"
      />
    </div>
    <InstructionSaveRow
      v-if="isInstructionEditable"
      :disable="isSaving || !canProceed"
      :is-saving="isSaving"
      :has-unsaved-changes="hasUnsavedChanges"
      @proceed="proceed"
    />
  </q-page>
</template>
<script lang="ts">
import { ref } from 'vue';
import { Component, Vue, Watch } from 'vue-facing-decorator';
import { Instruction, ShareRegister, TransactionType } from '@/models';
import ConvertibleDetails from '@shared/views/convertible/ConvertibleDetails.vue';
import { findInstruction, getInstructionDefaults, saveInstruction } from '@/models/instruction/instructionService';
import Stepper from '@/components/Stepper.vue';
import BondDetails from '@shared/views/bond/BondsDetails.vue';
import DebentureDetails from '@shared/views/debenture/DebentureDetails.vue';
import ShareholderContributionDetails from '@shared/views/shareholderContribution/ShareholderContributionDetails.vue';
import DestinationsTableCard from '@/views/issueWarrant/DestinationsTableCard.vue';
import DebtInstructionValidator from '@shared/models/instructionValidator/DebtInstructionValidator';
import { debounce } from 'lodash';
import { asModelId, isToNewPage, looksLikeId } from '@shared/utils';
import { Entity } from '@shared/web/models';
import { findEntityWithRouteParams } from '@shared/web/entity/entityService';
import { store } from '@shared/web/store';
import InstructionSaveRow from '@/components/InstructionSaveRow.vue';
import { InstructionState } from '@shared/models/types';
import { distributedTotalsInDebtInstruments } from '@shared/common';

const instructionDirty = ref(false);

@Component({
  components: {
    InstructionSaveRow,
    DestinationsTableCard,
    BondDetails,
    Stepper,
    ConvertibleDetails,
    DebentureDetails,
    ShareholderContributionDetails,
  },
  provide: { instructionDirty },
})
export default class IssueSecurityPage extends Vue {
  isSaving = false;
  debouncedSave = null;
  instruction: Instruction = null;
  shareRegister: ShareRegister = null;
  hasUnsavedChanges = false;
  entity: Entity = null;

  @Watch('hasUnsavedChanges')
  handleHasUnsavedChangesChange(value) {
    instructionDirty.value = value;
  }
  get validForSupplement() {
    return [
      TransactionType.ISSUE_BOND,
      TransactionType.ISSUE_CONVERTIBLE,
      TransactionType.ISSUE_DEBENTURE,
      TransactionType.ISSUE_WARRANT,
      TransactionType.SUPPLEMENT,
    ].includes(this.instruction.type);
  }

  get InstructionState() {
    return InstructionState;
  }

  get ready() {
    return this.instruction && this.shareRegister;
  }

  get isDataEditable() {
    if (store.entityAdmin?.limitedAccess === true) {
      return false;
    }
    if (this.instruction?.type === TransactionType.EXERCISE_CONVERTIBLE) {
      return false;
    }
    if (this.instruction?.type === TransactionType.EXERCISE_BOND) {
      return false;
    }
    return this.instruction?.isEditable;
  }

  get isInstructionEditable() {
    return this.instruction?.isEditable;
  }

  get canProceed() {
    return this.instruction?.isEditable && new DebtInstructionValidator(this.instruction).isValid();
  }

  get isNew() {
    const { id } = this.$route.params;
    return !looksLikeId(id);
  }

  get destinationsAmount() {
    return this.instruction.destinations.reduce<any>((amount, destination) => amount + destination.amount, 0);
  }

  created() {
    this.debouncedSave = debounce(async (syncFromBackend = false) => {
      this.isSaving = true;
      await this.save(syncFromBackend);
      this.isSaving = false;
    }, 1000);
  }
  @Watch('$route')
  mounted() {
    this.loadData();
  }

  async loadData(to?, from?) {
    if (isToNewPage(to, from)) {
      return;
    }
    const entity = await findEntityWithRouteParams(this.$route.params);
    const { shareRegister, instruction } = this.isNew
      ? await getInstructionDefaults({
          type: this.$route.meta.transactionType as TransactionType,
          entity: entity.id,
          instrument: this.$route.query.instrument as string,
        })
      : await findInstruction(this.$route.params.id);

    this.shareRegister = shareRegister;
    this.instruction = instruction;
  }

  async newSupplementInstruction() {
    const entity = await findEntityWithRouteParams(this.$route.params);
    const { corporateEvent } = this.instruction;
    const instrument = corporateEvent.instrumentSources[0].instrument;
    this.$router.push({ path: `${entity.baseUrl}/komplettering/ny`, query: { instrument: asModelId(instrument) } });
  }

  async save(syncFromBackend = false) {
    this.isSaving = true;
    const savedInstruction = (await saveInstruction(this.instruction)).model;
    if (!this.instruction.id) {
      window.history.pushState({}, '', savedInstruction.webUrl);
    }
    if (syncFromBackend) {
      this.instruction = savedInstruction;
    } else if (!this.instruction.id) {
      this.instruction.id = savedInstruction.id;
    }
    this.hasUnsavedChanges = false;
    this.isSaving = false;
  }

  async proceed() {
    await this.save(true);
    await this.$router.push({
      path: `${this.instruction.webUrl}/dokument`,
    });
  }

  async handleDetailsChange() {
    this.hasUnsavedChanges = true;
    this.recalculateDestinationsAmounts();
    await this.debouncedSave();
  }

  async handleDestinationsChange() {
    this.hasUnsavedChanges = true;
    this.recalculateDestinationsAmounts();
    // we need to re-sync here, otherwise we would send same destinations again and get dupes in the response from the backend
    await this.debouncedSave(true);
  }

  recalculateDestinationsAmounts() {
    distributedTotalsInDebtInstruments(this.instruction);
  }
}
</script>
