<template>
  <p v-if="!viewUrl">{{ viewName }}</p>
  <router-link v-else :to="viewUrl">{{ viewName }}</router-link>
  <p class="nationalid-small">{{ formatNationalId(entity) }}</p>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { Entity } from '@shared/web/models';

@Component({})
export default class EntityLink extends Vue {
  @Prop() entity: Entity;

  get viewName() {
    return this.entity?.viewName;
  }

  get viewUrl() {
    return this.entity?.viewUrl;
  }
}
</script>
