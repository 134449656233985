<template>
  <tr>
    <td class="text-left v-mid">{{ formatDate(instruction.updatedAt) }}</td>
    <td class="text-left v-mid">{{ transactionType(instruction.type) }}</td>
    <td class="text-left v-mid">{{ instructionName }}</td>
    <td class="text-left v-mid">{{ formatDay(rightsData.dueDate) }}</td>
    <td class="text-left v-mid">{{ formatNumber(rightsData.totalQuantity) }}</td>
    <td class="text-left v-mid">{{ calculatedState }}</td>
    <td class="text-left v-mid">
      <a-btn-link :to="{ path: `${instruction.entity.baseUrl}/${instruction.uriLabel}/${instruction.id}` }">{{
        instruction.isEditable && canEdit ? 'Ändra' : 'Detaljer'
      }}</a-btn-link>
    </td>
    <td class="text-center v-mid" v-if="canEdit">
      <q-btn
        class="delete text-center"
        flat
        round
        dense
        icon="svguse:/svg-icons.svg#icon-closedot"
        @click="deleteInstruction(instruction)"
        :disable="!instruction.isEditable"
        :class="{ inactive: !instruction.isEditable }"
      />
    </td>
    <td class="text-center v-mid actions-cell" v-if="canEdit">
      <q-btn
        no-wrap
        dense
        flat
        color="secondary"
        class="text-weight-regular"
        size="md"
        :disable="!executionAllowed"
        @click="createExerciseInstruction(instruction)"
        v-if="!exerciseInstruction"
        label="Lös in"
      />
      <a-btn-link
        v-if="exerciseInstruction"
        class="text-center"
        :to="{
          path: `${exerciseInstruction.entity.baseUrl}/${exerciseInstruction.uriLabel}/${exerciseInstruction.id}`,
        }"
        >Inlöst</a-btn-link
      >
    </td>
  </tr>
</template>

<script lang="ts">
import { Instruction } from '@/models';

import { formatDate } from '@shared/utils';
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { toInstructionStateLabel, toTransactionTypesLabel } from '@shared/common';
import RemoveInstructionModal from '@/views/editInstructionModal/RemoveInstructionModal.vue';
import router from '@/router';
import axios from 'axios';
import { asModelId } from '@/utils';
import { createConvertibleName } from '@shared/rightsIssue/utils';
import { store } from '@shared/web/store';

@Component({})
export default class SecuritiesRow extends Vue {
  @Prop() instruction: Instruction;
  @Prop() exerciseInstruction: Instruction;

  formatDate(date) {
    return formatDate(date, 'yyyy-MM-dd, HH:mm');
  }

  get instructionName() {
    if (this.rightsData.name) {
      return this.rightsData.name;
    }
    if (this.instruction.isConvertible) {
      return createConvertibleName(this.rightsData);
    }
    return null;
  }

  get canEdit() {
    return store.entityAdmin?.limitedAccess === false;
  }

  deleteInstruction(instruction) {
    this.$q
      .dialog({
        component: RemoveInstructionModal,
        componentProps: {
          value: instruction,
          modalName: 'optionsprogrammet',
        },
      })
      .onOk(() => {
        this.$emit('destroy', instruction);
      });
  }

  get rightsData() {
    return this.instruction.corporateEvent.rightsData;
  }

  get executionAllowed() {
    if (this.exerciseInstruction) {
      return this.exerciseInstruction.isEditable;
    }

    return this.instruction.calculatedState === 'EXECUTED';
  }

  get calculatedState() {
    return toInstructionStateLabel(this.instruction.calculatedState);
  }

  transactionType(state) {
    return toTransactionTypesLabel(state);
  }

  async createExerciseInstruction(instruction) {
    if (this.exerciseInstruction) {
      return await router.push({
        path: `${this.instruction.entity.baseUrl}/${this.exerciseInstruction.uriLabel}/${this.exerciseInstruction.id}`,
      });
    }
    const exerciseInstruction = (
      await axios.post('/webapi/instruction/new', {
        type: instruction.exerciseType,
        entity: asModelId(instruction.entity),
        rootInstruction: asModelId(instruction),
      })
    ).data;
    await router.push({
      path: `${instruction.entity.baseUrl}/${instruction.uriLabel}/${exerciseInstruction.id}`,
    });
  }
}
</script>
<style>
.actions-cell a.a-btn-link {
  display: inline-block !important;
}
</style>
