import { Model } from '../base';
import { InstructionParty, Position } from '@shared/web/models';
import { ISourcePosition, ITradeEvent } from '@shared/models/modelTypes';

export class SourcePosition extends Model implements ISourcePosition {
  position: Position;
  quantity: number;
  amount: number;
  static define = () => ({
    position: {
      model: Position,
    },
  });
}
export default class TradeEvent extends Model implements ITradeEvent {
  source: InstructionParty = null;
  sourcePositions: SourcePosition[] = null;
  tradeDate: Date = null;
  static define = () => ({
    source: {
      embeddedModel: InstructionParty,
      serializeChanges: true,
    },
    sourcePositions: {
      embeddedModel: [SourcePosition],
    },
    tradeDate: {
      type: 'date',
    },
  });
}
