import { Instruction } from '@shared/web/models';

export function distributedTotalsInDebtInstruments(instruction: Instruction) {
  const { corporateEvent, destinations } = instruction;
  const { distributeByValue, nominalAmountPerPiece } = corporateEvent.rightsData;

  let totalQuantity = 0;
  let nominalAmountTotal = 0;

  destinations.forEach(dest => {
    if (distributeByValue) {
      nominalAmountTotal += dest.amount;
    } else {
      dest.amount = dest.quantity * nominalAmountPerPiece;
      totalQuantity += dest.quantity;
    }
  });

  if (distributeByValue) {
    corporateEvent.rightsData.nominalAmountTotal = nominalAmountTotal;
  } else {
    corporateEvent.rightsData.totalQuantity = totalQuantity;
  }
}
