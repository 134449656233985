import axios from 'axios';
import { getToken } from '@shared/web/authentication';

axios.defaults.baseURL = import.meta.env.VITE_APP_API_BASE_URL;

axios.interceptors.request.use(
  config => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return Promise.resolve(config);
  },
  error => {
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  res => res,
  err => {
    const status = err.response?.status;
    if (status === 400 || status === 401) {
      const data = err.response.data;
      if (data?.text) {
        throw data;
      }
    }
    console.error(err);
    throw { text: 'Ett fel uppstod', ...err };
  },
);
