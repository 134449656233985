<template>
  <th class="sortable" :class="classes" @click="sort()">
    <slot v-if="!alignRight" /><q-icon name="arrow_downward" class="q-table__sort-icon" /><slot v-if="alignRight" />
  </th>
</template>
<script lang="ts">
import TableSettings from '@shared/web/models/shareRegister/TableSettings';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { SortProperty } from '@shared/models/modelTypes';

@Component
export default class SortableTH extends Vue {
  @Prop() property: SortProperty;
  @Prop() tableSettings: TableSettings;
  @Prop() alignRight: boolean;
  sort() {
    const property = this.property;
    if (property === this.tableSettings.sortProperty) {
      this.tableSettings.sortDesc = !this.tableSettings.sortDesc;
    }
    this.tableSettings.sortProperty = property;
  }

  get classes() {
    return {
      'sort-desc': this.property === this.tableSettings.sortProperty && this.tableSettings.sortDesc,
      'text-left': !this.alignRight,
      'text-right': this.alignRight,
    };
  }
}
</script>
