<template>
  <div class="IssueWarrantsTable">
    <h4 class="table-header">{{ header }}</h4>
    <p v-if="subheader && instructions.length">{{ subheader }}</p>
    <q-markup-table flat class="standard-table position-table" separator="horizontal">
      <thead class="has-background">
        <tr>
          <th class="text-left">Senast ändrad</th>
          <th class="text-left">Typ</th>
          <th class="text-left">Namn</th>
          <th class="text-left">Slutdag</th>
          <th class="text-left">Antal utgivna</th>
          <th class="text-left">Status</th>
          <th class="text-left">Se mer</th>
          <th v-if="canEdit" class="text-center narrow">Ta bort</th>
          <th v-if="canEdit" class="text-center narrow">Lös in</th>
        </tr>
      </thead>
      <tbody class="two-row">
        <SecuritiesRow
          v-for="instruction in instructions"
          :key="instruction.id"
          :instruction="instruction"
          :exerciseInstruction="getExerciseInstruction(instruction)"
          @destroy="$emit('destroy', $event)"
        />
      </tbody>
    </q-markup-table>
  </div>
</template>
<script lang="ts">
import { Instruction } from '@/models';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import SecuritiesRow from './SecuritiesRow.vue';
import { sortBy, reverse } from 'lodash';
import { store } from '@shared/web/store';

@Component({
  components: { SecuritiesRow },
})
export default class SecuritiesTable extends Vue {
  @Prop() value: Array<Instruction>;
  @Prop() header: string;
  @Prop({ default: '' }) subheader: string;
  @Prop({ default: () => [] }) exerciseInstructions: Instruction[];

  get instructions() {
    return reverse(sortBy(this.value, 'updatedAt'));
  }

  get canEdit() {
    return store.entityAdmin?.limitedAccess === false;
  }

  getExerciseInstruction(issueInstruction: Instruction) {
    return this.exerciseInstructions.find(
      i => i.corporateEvent.instrumentSources[0].originInstrument === issueInstruction.destinations[0].instrument,
    );
  }
}
</script>
<style lang="scss">
.IssueWarrantsTable {
  max-width: 100%;
}
</style>
