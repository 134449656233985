<template>
  <q-page class="InvestmentOfferDetails">
    <div class="row">
      <h1>Investeringserbjudande</h1>
    </div>
    <q-separator />
    <div class="standard-table" v-if="investmentOffer">
      <q-card flat class="investment_offer_detail bg-white q-pa-md q-mb-sm">
        <div class="row investment-offer--title">
          <h1 v-html="investmentOffer.title" class="col-12"></h1>
        </div>
        <div class="row editor-text">
          <p v-html="investmentOffer.text" class="col-12"></p>
        </div>
        <div v-if="investmentOffer.files.length > 0" class="row">
          <div class="investment_offer--button col-12">
            <div class="q-pt-md q-gutter-sm">
              <p>Läs mer genom att ladda ned dokument nedan.</p>
              <div v-for="file in investmentOffer.files" :key="file.url">
                <td>
                  <a :href="file.url" class="text-primary" target="_blank" @click.prevent="openFile(file)">{{
                    file.name
                  }}</a>
                </td>
              </div>
            </div>
          </div>
        </div>
      </q-card>
    </div>
    <div v-else>
      <p>Investeringserbjudandet kunde inte hittas</p>
    </div>
    <q-card-actions align="right" v-if="!isBO">
      <q-btn label="Intresseanmälan" v-bind="$defs.btn" @click="openModal" />
    </q-card-actions>
  </q-page>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { formatDateTime } from '@shared/utils';
import { fetchInvestmentOfferById } from '@shared/web/models/investmentOffer/investmentOffersService';
import { Entity } from '@shared/web/models';
import './investment-offer-style.scss';

import axios from 'axios';
import DeclareInterestModal from './DeclareInterestModal.vue';
import { InvestmentOffer } from '@shared/models/types';
import { sendInvestmentOfferMail } from '@shared/services/mailService';
import { store } from '@shared/web/store';
import { isBackOffice } from '@shared/web/utils';
@Component
export default class InvestmentOfferDetail extends Vue {
  @Prop() id;
  investmentOffer;
  entity: Entity;
  isBO = isBackOffice();
  formatDateTime(datetime: string | Date) {
    return formatDateTime(new Date(datetime));
  }

  async mounted() {
    this.entity = store?.profile?.entity as Entity | undefined;
    this.investmentOffer = await fetchInvestmentOfferById(this.id);
    this.investmentOffer.files = (await axios.get(`/api/investment-offer/list/${this.investmentOffer.id}`)).data;
  }
  async openFile(file) {
    const { data } = await axios.get(file.url);
    window.open(data.url, '_blank');
  }

  confirmationModal() {
    this.$q.dialog({
      title: 'Tack för ditt meddelande.',
      message:
        'Vi återkommer inom kort. Har du frågor kan du alltid ringa oss på 08 409 100 80 eller maila till info@kaptena.se',
    });
  }

  async openModal() {
    return this.$q.dialog({
      component: DeclareInterestModal,
      componentProps: {
        investmentOffer: this.investmentOffer,
        entity: this.entity,
        title: 'Intresseanmälan att delta i nyemission i:',
        note: 'OBS: intresseanmälan är inte bindande',
        onSubmit: async (investmentOffer: InvestmentOffer) => {
          await sendInvestmentOfferMail(investmentOffer);
          this.confirmationModal();
        },
      },
    });
  }
}
</script>
<style type="scss">
.InvestmentOfferDetails {
  .investment-offer--title h1 {
    border-bottom: 1px solid #7b756f;
    color: black;
    display: block;
    font-size: 2rem;
    margin-top: 0.67rem;
    margin-bottom: 0.67rem;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }

  .investment_offer-details-footer {
    border-top: 1px solid #7b756f;
  }
}
</style>
