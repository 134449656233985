<template>
  <div class="IssueShareContent">
    <div class="flex row q-gutter-x-lg">
      <div class="col-2">
        <p class="B1Small-label">Betald teckningsaktie</p>
        <q-input
          v-model="issueShareData.name"
          v-bind="$defs.input"
          :readonly="readonly"
          @change="change"
          :rules="validation.required('Namn för BTA')"
        />
      </div>
      <div class="col-2">
        <p class="B1Small-label">Antal nya aktier</p>
        <a-input-number
          v-model="issueShareData.totalQuantity"
          v-bind="$defs.input"
          :readonly="readonly"
          :fractionDigits="0"
          @change="change"
        />
      </div>
      <div class="col-2">
        <p class="B1Small-label">Tecknade aktier</p>

        <a-input-number
          v-model="capitalInfo.totalQuantity"
          v-bind="$defs.input"
          :readonly="true"
          :fractionDigits="0"
          class="text-right"
          :input-class="{ valid: capitalInfo.validQuantity, validation: true }"
        />
      </div>
      <div class="col-2">
        <p class="B1Small-label">Teckningskurs</p>
        <a-input-number
          v-model="instruction.price"
          v-bind="$defs.input"
          :readonly="readonly"
          :rules="priceRules"
          :maximumFractionDigits="6"
          :minimumFractionDigits="2"
        />
      </div>
      <div class="col-2">
        <p class="B1Small-label">Röstandel</p>
        <a-input-number
          v-model="issueShareData.votingPower"
          v-bind="$defs.input"
          :readonly="readonly"
          :fractionDigits="2"
          @change="change"
          :rules="validation.requiredNumber"
        />
      </div>
      <div class="col-2">
        <p class="B1Small-label">
          Införd i aktieboken
          <a-info-btn>
            <p>Införd i aktieboken är den dagen deltagare i nyemissionen kommer bli införda i aktieboken</p>
          </a-info-btn>
        </p>
        <a-input-date
          v-bind="$defs.input"
          placeholder="yyyy-mm-dd"
          v-model="instruction.settleDate"
          @change="change"
          :readonly="readonly"
        ></a-input-date>
      </div>
    </div>
    <div class="table-header">
      <h4>Teckna emission</h4>
      <ExportLinks>
        <ExportExcel :exporter="excelExporter" />
      </ExportLinks>
    </div>
    <DestinationsTable
      :shareRegister="shareRegister"
      v-if="shareRegister"
      :instruction="instruction"
      :readonly="readonly"
      @change="changeWithSync"
    />
    <a-btn-link class="test-clear" v-if="!readonly" @click="clearInstruction">Rensa</a-btn-link>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { Instruction, ShareRegister } from '@/models';
import { validation } from '@shared/common';
import DestinationsTable from './DestinationsTable.vue';
import ExportExcel from '@shared/views/excel/ExportExcel.vue';
import createExcelExporter from '@/views/excel/rightsIssueExport';
import ExportLinks from '@shared/components/ExportLinks.vue';
import issueShareValidator from '@shared/models/instructionValidator/issueShareValidator';
import { requiredNumber } from '@shared/utils/validation';
import ClearInstructionModal from '@/views/editInstructionModal/ClearInstructionModal.vue';
@Component({
  components: { DestinationsTable, ExportExcel, ExportLinks },
})
export default class IssueShareContent extends Vue {
  @Prop() instruction: Instruction;
  @Prop() shareRegister: ShareRegister;
  @Prop({ default: () => false }) readonly: boolean;

  change() {
    this.$emit('change');
  }

  changeWithSync() {
    this.$emit('change', true);
  }

  get validation() {
    return validation;
  }

  get issueShareData() {
    return this.instruction.corporateEvent.issueShareData;
  }

  get validator() {
    return issueShareValidator(this.instruction, this.instruction.entity);
  }

  get priceRules() {
    return [requiredNumber];
  }

  get capitalInfo() {
    return this.validator.getCapitalInfo();
  }

  get excelExporter() {
    return createExcelExporter(this.shareRegister, this.instruction);
  }

  clearInstruction() {
    this.$q
      .dialog({
        component: ClearInstructionModal,
        componentProps: {
          id: this.instruction.id,
          modalName: 'nyemissionen',
        },
      })
      .onOk(instruction => {
        this.$emit('clear', instruction);
      });
  }
}
</script>
<style lang="scss">
.IssueShareContent {
  .table-header {
    margin-top: 40px;
  }
}
</style>
