<template>
  <q-input
    v-model="leiInput"
    fill-input
    hint="Frivillig uppgift"
    :error-message="errorMessage"
    :error="errorMessage != null"
    :lazy-rules="true"
    v-bind="$defs.input"
    label="LEI"
    class="FindEntityLEI"
    ref="input"
  />
</template>

<script lang="ts">
import { Component, Vue, Prop, Ref, Watch } from 'vue-facing-decorator';
import { lookupEntityLei } from '@shared/web/entity/entityService';
import { Entity } from '@shared/web/models';
import { isLEI } from '@shared/utils/validation';

@Component({})
export default class FindEntityLEI extends Vue {
  @Ref() readonly input;
  @Prop() modelValue: string;
  @Prop() entity: Entity;
  errorMessage: string = null;
  inputText: string = '';

  get leiInput() {
    return this.inputText;
  }

  set leiInput(value: string) {
    this.handleInput(value);
  }

  async handleInput(value) {
    this.inputText = value;

    await this.searchEntity(value);
    this.$emit('update:modelValue', value);
  }

  @Watch('modelValue', { immediate: true })
  setModelValue(val) {
    this.inputText = val;
  }

  async searchEntity(lei: string) {
    const emitEntity = (entity: Entity) => {
      this.$emit('search-result', entity);
    };

    const globalEntity = await this.findGlobalEntity(lei);

    if (globalEntity) {
      emitEntity(globalEntity);
      return;
    }

    if (this.entity?.isGlobal) {
      emitEntity(Entity.newModel({ person: {}, legalEntity: { LEI: lei }, isPerson: false }));
    }
  }

  async findGlobalEntity(lei: string): Promise<Entity> {
    if (!isLEI(lei)) {
      return null;
    }
    try {
      const entity = await lookupEntityLei({ lei });
      this.errorMessage = null;
      return entity;
    } catch (error) {
      console.error('Search error:', error);
      this.errorMessage = error.text;
    }
  }
}
</script>
