<template>
  <q-breadcrumbs class="Stepper" gutter="none">
    <template v-slot:separator>
      <q-icon size="sm" name="chevron_right" color="gray-3" />
    </template>
    <q-breadcrumbs-el
      v-for="step in steps[currentPageType]"
      :key="step.nr"
      :to="{ path: urlId ? step.url : '' }"
      :data-index="step.nr"
      :label="step.label"
      :class="[currentstep > step.nr || (currentstep === step.nr && isDone) ? 'is-done' : '']"
      :disable="currentstep < step.nr"
      icon="circle"
      active-class="is-active"
    />
  </q-breadcrumbs>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import store from '@/store';
import { Entity } from '@/models';
@Component({
  components: {},
})
export default class Stepper extends Vue {
  @Prop({ default: true }) isDone: boolean;
  @Prop({ default: 1 }) currentstep: number;
  @Prop({ required: true }) pageType: string;
  @Prop() urlId: string;

  get currentPageType() {
    if (this.pageType.includes('_')) {
      return this.pageType.toLowerCase().replace(/_([a-z])/g, g => g[1].toUpperCase());
    }

    return this.pageType;
  }

  get steps() {
    const entity = store.activeEntity as Entity;
    return {
      SUPPLEMENT: [
        {
          label: 'Komplettera deltagande',
          url: `${entity?.baseUrl}/komplettering/${this.urlId}`,
          nr: 1,
        },
        {
          label: 'Bifoga dokument',
          url: `${entity?.baseUrl}/komplettering/${this.urlId}/dokument`,
          nr: 2,
        },
        {
          label: 'Granska',
          url: `${entity?.baseUrl}/komplettering/${this.urlId}/granska`,
          nr: 3,
        },
        {
          label: 'Godkänn och skicka',
          url: `${entity?.baseUrl}/komplettering/${this.urlId}/skickain`,
          nr: 4,
        },
      ],
      shareRegister: [
        {
          label: 'Fördela aktier',
          url: `/${this.urlId}/skapa-aktiebok`,
          nr: 1,
        },
        {
          label: 'Bifoga dokument',
          url: `/${this.urlId}/skapa-aktiebok/dokument`,
          nr: 2,
        },
        {
          label: 'Granska',
          url: `/${this.urlId}/skapa-aktiebok/granska`,
          nr: 3,
        },
        {
          label: 'Godkänn och skicka in',
          url: `/${this.urlId}/skapa-aktiebok/skickain`,
          nr: 4,
        },
      ],
      tradeEvent: [
        {
          label: 'Fördela värdepapper',
          url: `${entity?.baseUrl}/agarbyte/${this.urlId}`,
          nr: 1,
        },
        {
          label: 'Bifoga dokument',
          url: `${entity?.baseUrl}/agarbyte/${this.urlId}/dokument`,
          nr: 2,
        },
        {
          label: 'Granska',
          url: `${entity?.baseUrl}/agarbyte/${this.urlId}/granska`,
          nr: 3,
        },
        {
          label: 'Godkänn och skicka',
          url: `${entity?.baseUrl}/agarbyte/${this.urlId}/skickain`,
          nr: 4,
        },
      ],
      rightsIssue: [
        {
          label: 'Fördela aktier',
          url: `${entity?.baseUrl}/nyemission/${this.urlId}`,
          nr: 1,
        },
        {
          label: 'Bifoga dokument',
          url: `${entity?.baseUrl}/nyemission/${this.urlId}/dokument`,
          nr: 2,
        },
        {
          label: 'Granska',
          url: `${entity?.baseUrl}/nyemission/${this.urlId}/granska`,
          nr: 3,
        },
        {
          label: 'Godkänn och skicka',
          url: `${entity?.baseUrl}/nyemission/${this.urlId}/skickain`,
          nr: 4,
        },
      ],
      split: [
        {
          label: 'Fördela aktier',
          url: `${entity?.baseUrl}/split/${this.urlId}`,
          nr: 1,
        },
        {
          label: 'Bifoga dokument',
          url: `${entity?.baseUrl}/split/${this.urlId}/dokument`,
          nr: 2,
        },
        {
          label: 'Granska',
          url: `${entity?.baseUrl}/split/${this.urlId}/granska`,
          nr: 3,
        },
        {
          label: 'Godkänn och skicka',
          url: `${entity?.baseUrl}/split/${this.urlId}/skickain`,
          nr: 4,
        },
      ],
      issueWarrant: [
        {
          label: 'Teckningsoptionsprogram',
          url: `${entity?.baseUrl}/optionsprogram/${this.urlId}`,
          nr: 1,
        },
        {
          label: 'Bifoga dokument',
          url: `${entity?.baseUrl}/optionsprogram/${this.urlId}/dokument`,
          nr: 2,
        },
        {
          label: 'Granska',
          url: `${entity?.baseUrl}/optionsprogram/${this.urlId}/granska`,
          nr: 3,
        },
        {
          label: 'Godkänn och skicka',
          url: `${entity?.baseUrl}/optionsprogram/${this.urlId}/skickain`,
          nr: 4,
        },
      ],
      issueConvertible: [
        {
          label: 'Emittera konvertibel',
          url: `${entity?.baseUrl}/konvertibler/${this.urlId}`,
          nr: 1,
        },
        {
          label: 'Bifoga dokument',
          url: `${entity?.baseUrl}/konvertibler/${this.urlId}/dokument`,
          nr: 2,
        },
        {
          label: 'Granska',
          url: `${entity?.baseUrl}/konvertibler/${this.urlId}/granska`,
          nr: 3,
        },
        {
          label: 'Godkänn och skicka',
          url: `${entity?.baseUrl}/konvertibler/${this.urlId}/skickain`,
          nr: 4,
        },
      ],
      exerciseWarrant: [
        {
          label: 'Lös option',
          url: `${entity?.baseUrl}/optionsprogram/${this.urlId}`,
          nr: 1,
        },
        {
          label: 'Bifoga dokument',
          url: `${entity?.baseUrl}/optionsprogram/${this.urlId}/dokument`,
          nr: 2,
        },
        {
          label: 'Granska',
          url: `${entity?.baseUrl}/optionsprogram/${this.urlId}/granska`,
          nr: 3,
        },
        {
          label: 'Godkänn och skicka',
          url: `${entity?.baseUrl}/optionsprogram/${this.urlId}/skickain`,
          nr: 4,
        },
      ],
      exerciseConvertible: [
        {
          label: 'Lös konvertibel',
          url: `${entity?.baseUrl}/konvertibler/${this.urlId}`,
          nr: 1,
        },
        {
          label: 'Bifoga dokument',
          url: `${entity?.baseUrl}/konvertibler/${this.urlId}/dokument`,
          nr: 2,
        },
        {
          label: 'Granska',
          url: `${entity?.baseUrl}/konvertibler/${this.urlId}/granska`,
          nr: 3,
        },
        {
          label: 'Godkänn och skicka',
          url: `${entity?.baseUrl}/konvertibler/${this.urlId}/skickain`,
          nr: 4,
        },
      ],
      issueBond: [
        {
          label: 'Emittera obligation',
          url: `${entity?.baseUrl}/obligationer/${this.urlId}`,
          nr: 1,
        },
        {
          label: 'Bifoga dokument',
          url: `${entity?.baseUrl}/obligationer/${this.urlId}/dokument`,
          nr: 2,
        },
        {
          label: 'Granska',
          url: `${entity?.baseUrl}/obligationer/${this.urlId}/granska`,
          nr: 3,
        },
        {
          label: 'Godkänn och skicka',
          url: `${entity?.baseUrl}/obligationer/${this.urlId}/skickain`,
          nr: 4,
        },
      ],
      exerciseBond: [
        {
          label: 'Lös obligation',
          url: `${entity?.baseUrl}/obligationer/${this.urlId}`,
          nr: 1,
        },
        {
          label: 'Bifoga dokument',
          url: `${entity?.baseUrl}/obligationer/${this.urlId}/dokument`,
          nr: 2,
        },
        {
          label: 'Granska',
          url: `${entity?.baseUrl}/obligationer/${this.urlId}/granska`,
          nr: 3,
        },
        {
          label: 'Godkänn och skicka',
          url: `${entity?.baseUrl}/obligationer/${this.urlId}/skickain`,
          nr: 4,
        },
      ],
      issueDebenture: [
        {
          label: 'Emittera vinstandelslån',
          url: `${entity?.baseUrl}/vinstandelslan/${this.urlId}`,
          nr: 1,
        },
        {
          label: 'Bifoga dokument',
          url: `${entity?.baseUrl}/vinstandelslan/${this.urlId}/dokument`,
          nr: 2,
        },
        {
          label: 'Granska',
          url: `${entity?.baseUrl}/vinstandelslan/${this.urlId}/granska`,
          nr: 3,
        },
        {
          label: 'Godkänn och skicka',
          url: `${entity?.baseUrl}/vinstandelslan/${this.urlId}/skickain`,
          nr: 4,
        },
      ],
      exerciseDebenture: [
        {
          label: 'Lös vinstandelslån',
          url: `${entity?.baseUrl}/vinstandelslan/${this.urlId}`,
          nr: 1,
        },
        {
          label: 'Bifoga dokument',
          url: `${entity?.baseUrl}/vinstandelslan/${this.urlId}/dokument`,
          nr: 2,
        },
        {
          label: 'Granska',
          url: `${entity?.baseUrl}/vinstandelslan/${this.urlId}/granska`,
          nr: 3,
        },
        {
          label: 'Godkänn och skicka',
          url: `${entity?.baseUrl}/vinstandelslan/${this.urlId}/skickain`,
          nr: 4,
        },
      ],
      newAdmin: [
        {
          label: 'Uppgifter',
          url: `${this.urlId}/admin/skapa`,
          nr: 1,
        },
        {
          label: 'Granska',
          url: `${this.urlId}/admin/granska`,
          nr: 2,
        },
        {
          label: 'Godkänn och skicka',
          url: `${this.urlId}/admin/skickain`,
          nr: 3,
        },
      ],
    };
  }
}
</script>
<style lang="scss">
.Stepper {
  background: $grey-0;
  padding: 5px 25px 5px 1px;
  min-height: 2.572em;
  border-radius: 25px;
  font-size: 12px;
  width: fit-content;
  margin: 30px 0;

  .q-link {
    position: relative;
    color: $grey-4;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.6px;
    margin: 0 4px;
    &::before {
      content: attr(data-index);
      display: block;
      top: 0;
      left: 0;
      position: absolute;
      color: #fff;
      z-index: 1;
      line-height: 31px;
      width: 30px;
      text-align: center;
    }
    &.is-active {
      color: $grey-6;
    }
    &.is-done {
      color: $secondary;
      &::before {
        font-family: 'Material Icons';
        content: '\e876';
        font-size: 18px;
      }
    }
    .q-icon {
      margin-right: 5px;
      font-size: 30px;
    }
  }
}
</style>
