<template>
  <div class="section PrivateOwner">
    <h5>Ägare</h5>
    <FindEntityEmail v-model="email" class="q-mb-md email-input" @search-result="handleSearchResult" :entity="entity" />
    <div class="row q-gutter-x-lg q-pb-md">
      <q-input
        class="col"
        v-bind="$defs.input"
        v-model="entity.person.firstName"
        :rules="validation.required('Förnamn')"
        label="Förnamn"
        :readonly="readonly"
        :disable="isDisabled"
      ></q-input>
      <q-input
        class="col"
        v-bind="$defs.input"
        v-model="entity.person.surName"
        :rules="validation.required('Efternamn')"
        label="Efternamn"
        :readonly="readonly"
        :disable="isDisabled"
      ></q-input>
      <SelectCountry
        class="col"
        v-bind="$defs.input"
        v-model="entity.countryCode"
        :rules="validation.required('Land')"
        label="Land"
        :readonly="readonly"
        :disable="isDisabled"
      />
    </div>
    <div class="row q-gutter-x-lg">
      <q-input
        class="col"
        v-bind="$defs.input"
        v-model="entity.nationalId"
        label="Personnummer"
        hint="Frivillig uppgift"
        :readonly="readonly"
        :disable="isDisabled"
      ></q-input>
      <q-input
        class="col"
        v-bind="$defs.input"
        v-model="contactInfo.phone"
        hint="Frivillig uppgift"
        label="Telefon"
      ></q-input>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import SelectCountry from '@shared/components/SelectCountry.vue';
import { Entity, ContactInfo } from '@shared/web/models';
import { validation } from '@shared/common';
import FindEntityEmail from './FindEntityEmail.vue';

@Component({
  components: { SelectCountry, FindEntityEmail },
})
export default class PrivateOwner extends Vue {
  @Prop() entity: Entity;
  @Prop() contactInfo: ContactInfo;
  @Prop() readonly: boolean;
  @Prop() disable: boolean;

  get email() {
    return this.entity?.addresses[0]?.email || this.contactInfo.email;
  }

  set email(value: string) {
    this.contactInfo.email = value;
  }

  handleSearchResult(searchResult: Entity) {
    this.$emit('update:modelValue', searchResult);
  }

  get isGlobal() {
    return this.entity?.isGlobal;
  }

  get isDisabled() {
    return this.disable || this.isGlobal;
  }

  get validation() {
    return validation;
  }
}
</script>
<style lang="scss">
.PrivateOwner {
  .email-input {
    max-width: 15.75rem;
  }
  fieldset {
    border: none;
    padding: 0;
    margin: 0;
    &.investorManager {
      margin-top: 1.4em;
    }
  }
}
</style>
