import { Model } from '../base';
import { Role, Entity, EntityConnection, ContactInfo } from '@shared/web/models';
import { Structure } from '@shared/views/common/treeStructureList/treeStructureListTypes';
import { ILegalEntity } from '@shared/models/modelTypes';
export default class LegalEntity extends Model implements ILegalEntity {
  name: string = null;
  shortName?: string = null;
  LEI: string = null;
  naceCode?: string = null;

  roles: Array<Role> = [];
  investorManager: Entity = null;
  managedByKaptena: boolean;
  parentCompany?: Entity = null;
  cachedStructure?: Structure = null;
  entityConnections?: Array<EntityConnection> = null;
  contactPersons?: Array<ContactInfo>;

  static define = () => ({
    roles: {
      embeddedModel: [Role],
    },
    investorManager: {
      model: Entity,
    },
    parentCompany: {
      model: Entity,
    },
    entityConnections: {
      embeddedModel: [EntityConnection],
    },
    contactPersons: {
      embeddedModel: [ContactInfo],
    },
  });
}
