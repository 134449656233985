<template>
  <q-input
    v-model="emailInput"
    fill-input
    hint="Frivillig uppgift"
    :error-message="errorMessage"
    :error="errorMessage != null"
    :lazy-rules="true"
    v-bind="$defs.input"
    label="E-post"
    class="FindEntityEmail"
    ref="input"
  />
</template>

<script lang="ts">
import { Component, Vue, Prop, Ref, Watch } from 'vue-facing-decorator';
import { lookupEntityEmail } from '@shared/web/entity/entityService';
import { Address, Entity } from '@shared/web/models';
import isEmail from 'validator/lib/isEmail';

@Component({})
export default class FindEntityEmail extends Vue {
  @Ref() readonly input;
  @Prop() modelValue: string;
  @Prop() entity: Entity;
  errorMessage: string = null;
  inputText: string = '';

  get emailInput() {
    return this.inputText;
  }

  set emailInput(value: string) {
    this.handleInput(value);
  }

  async handleInput(value) {
    this.inputText = value;
    await this.searchEntity(value);
    this.$emit('update:modelValue', value);
  }

  @Watch('modelValue', { immediate: true })
  setModelValue(val) {
    this.inputText = val;
  }

  get validate() {
    return this.input.validate();
  }

  async searchEntity(email: string) {
    const globalEntity = await this.findGlobalEntity(email);

    const emitEntity = (entity: Entity) => {
      this.$emit('search-result', entity);
    };

    const entity = this.entity?.isGlobal
      ? Entity.newModel({ isPerson: true, addresses: [{ ...new Address(), email }], person: {}, LegalEntity: {} })
      : this.entity;

    if (globalEntity) {
      emitEntity(globalEntity);
    } else {
      if (email.length > 0) {
        if (!isEmail(email)) {
          this.errorMessage = 'Ange en giltig e-postadress';
        } else this.errorMessage = null;
      } else {
        this.errorMessage = null;
      }

      emitEntity(entity);
    }
  }

  async findGlobalEntity(email: string): Promise<Entity> {
    if (!isEmail(email)) {
      return null;
    }

    try {
      const entity = await lookupEntityEmail({ email });

      return entity.id ? entity : null;
    } catch (error) {
      console.error('Search error:', error);
      this.errorMessage = error.text;
    }
  }
}
</script>
