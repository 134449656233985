<template>
  <div class="GeneralMeetingView">
    <div class="description q-mb-md">
      <p>
        Efter att ni har skickat ut en kallelse erbjuder denna funktion möjligheten att förenkla administrationen före,
        under och efter en bolagsstämma.
      </p>
      <p>
        <strong>Före:</strong> Kryssa för löpande de aktieägare som anmäler sig till den kommande bolagsstämman, gjorda
        markeringar kommer sparas direkt och även finnas kvar nästa gång du använder funktionen.
      </p>
      <p>
        <strong>Under:</strong> På dagen för bolagsstämma skall de aktieägare som anlänt för att delta på stämman
        registreras för att en röstlängd skall kunna skapas. Detta görs i kolumnen deltar. Varefter deltagarna
        registreras kommer antalet röster och röstlängd automatiskt att räknas ut. Röstlängden skall godkännas av
        stämman.
      </p>
      <p>
        <strong>Efter:</strong> I funktionen kan man även ladda ner all information på denna sida till ett exceldokument
        (se länk nedan) som ett stöd för att kunna dokumentera och spara röstlängden från den genomförda bolagsstämman.
        I exceldokumentet kan man markera de som närvarade för att skriva ut röstlängden med endast närvarande
        aktieägare och röster vilken kan sparas och användas som bilaga till bolagsstämmoprotokollet.
      </p>
      <p>
        <strong>Tips:</strong> I fliken malldokument finns en checklista inför bolagsstämma, även förslag på mallar för
        kallelse och protokoll finns där att använda. <br />Det är möjligt att rensa alla markeringar längst ner med
        funktionen “rensa”.
      </p>
    </div>
    <ExportLinks>
      <ExportExcel class="export-link-box test-export-link-box" :exporter="generalMeetingExcel" />
    </ExportLinks>
    <GeneralMeetingTable :shareRegister="shareRegister" :generalMeetingParticipation="generalMeetingParticipation" />
  </div>
</template>
<script lang="ts">
// @ts-nocheck
import { ShareRegister, IssuerInfo, GeneralMeetingParticipation } from '@/models';
import GeneralMeetingTable from './GeneralMeetingTable.vue';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import ExportLinks from '@shared/components/ExportLinks.vue';
import ExportExcel from '@shared/views/excel/ExportExcel.vue';
import generalMeetingExcel from '@shared/excel/generalMeetingRegisterExport';

import { findIssuerInfo } from '@shared/web/models/entity/issuerInfoService';

@Component({
  components: {
    ExportLinks,
    ExportExcel,
    GeneralMeetingTable,
  },
})
export default class GeneralMeetingView extends Vue {
  @Prop() shareRegister: ShareRegister;
  @Prop() generalMeetingParticipation: GeneralMeetingParticipation;

  issuerInfo: IssuerInfo = null;
  loading = true;

  get generalMeetingExcel() {
    return generalMeetingExcel(this.shareRegister, this.generalMeetingParticipation, this.issuerInfo);
  }

  async mounted() {
    this.issuerInfo = await findIssuerInfo(this.shareRegister.entity);
  }
}
</script>
<style lang="scss">
.GeneralMeetingView {
  position: relative;
  .description {
    max-width: 75ch;
  }
  .q-tab-panels-bg-transparent {
    background: transparent !important;
  }
  .export-link-box {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
}
</style>
