<template>
  <q-dialog ref="dialog" persistent>
    <div class="q-dialog-plugin EditPositionHistoryModal q-pa-lg">
      <h2 class="text-grey-5 text-weight-regular">Historik för innehav</h2>
      <q-form ref="form">
        <a-page-section class="padded">
          <q-markup-table flat class="test-sharedata-table" separator="none">
            <thead>
              <tr>
                <th class="text-left">Ägare</th>
                <th class="text-left">Transaktion</th>
                <th class="text-left">Datum</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="position in history" :key="position.id">
                <td class="text-left">
                  <FindEntity v-model="position.owner" v-bind="$defs.input" :readonly="disableUpdateOwner(position)" />
                </td>

                <td class="text-left">
                  <div v-if="disableUpdateOwner(position)">{{ transactionTypeLabel(position.type) }}</div>
                  <a-select
                    v-bind="$defs.input"
                    v-model="position.type"
                    :options="typeOptions"
                    emit-value
                    map-options
                    v-else
                  ></a-select>
                </td>
                <td class="text-left">
                  <a-input-date
                    v-model="position.settleDate"
                    v-bind="$defs.input"
                    :readonly="disableUpdateProperties(position)"
                  />
                </td>
              </tr>
              <tr>
                <td><a-table-row-btn @click="addPosition" label="Lägg till tidigare ägare" /></td>
              </tr>
            </tbody>
          </q-markup-table>
        </a-page-section>
        <div class="flex q-mb-lg footer-wrapper">
          <div class="btn-wrapper flex row">
            <q-btn v-bind="$defs.btn" flat @click="hide" class="cancelBtn q-mr-md">Avbryt</q-btn>
            <q-btn label="Spara" v-bind="$defs.btn" :loading="loading" @click="save" class="save-btn" />
          </div>
        </div>
      </q-form>
    </div>
  </q-dialog>
</template>
<script lang="ts">
// @ts-nocheck
import { Position, TransactionType } from '@/models';
import { lastElement } from '@/utils';

import { Component, Vue, Prop } from 'vue-facing-decorator';
import { DialogComponent, FormComponent } from '@shared/mixins';
import { toTransactionTypeOptions } from '@shared/common';

import FindEntity from '@shared/views/editPositionModal/FindEntity.vue';
import { toTransactionTypeLabel } from '@shared/common';
import { loadPositionHistory, savePositionHistory } from '@shared/web/models/position/positionService';
import { HistoryEntry } from '@shared/web/models/shareRegister/PositionHistory';

@Component({
  components: { FindEntity },
  mixins: [DialogComponent, FormComponent],
})
export default class EditPositionHistoryModal extends Vue {
  @Prop() value: Position;

  history: Array<HistoryEntry>;

  transactionTypeLabel(type: TransactionType) {
    return toTransactionTypeLabel(type);
  }

  get typeOptions() {
    const {
      TRADE,
      GIFT,
      INHERITED,
      INSURANCE_PURCHASE,
      INSURANCE_REPURCHASE,
      PLEDGE,
      SHAREREGISTER_CREATED,
      RIGHTS_ISSUE,
    } = TransactionType;

    const typeOptions = toTransactionTypeOptions([
      TRADE,
      GIFT,
      INHERITED,
      INSURANCE_PURCHASE,
      INSURANCE_REPURCHASE,
      PLEDGE,
      SHAREREGISTER_CREATED,
      RIGHTS_ISSUE,
    ]);

    return typeOptions;
  }

  addPosition() {
    const lastPosition = lastElement(this.history);
    this.history.push(HistoryEntry.newModel({ settleDate: lastPosition.settleDate, manuallyCreated: true }));
  }

  async created() {
    this.history = await loadPositionHistory(this.value);
  }

  disableUpdateOwner(position: HistoryEntry) {
    return !position.manuallyCreated;
  }

  disableUpdateProperties(position: HistoryEntry) {
    return !position.manuallyCreated && position.type !== TransactionType.SHAREREGISTER_CREATED;
  }

  async save() {
    await savePositionHistory(this.history);
    this.hide();
  }
}
</script>
<style lang="scss">
.EditPositionHistoryModal {
  width: 1000px !important;
  max-width: 1000px !important;
  background-color: white;

  .btn-wrapper {
    margin-left: auto;
  }

  .footer-wrapper {
    flex-flow: wrap;
  }

  .cancelBtn {
    font-size: 14px;
    color: $primary;
  }

  .FindEntity {
    position: relative;
    top: 10px;
  }

  .q-table tbody tr td .q-field__control {
    height: 48px;
    min-height: 48px;
  }
}
</style>
