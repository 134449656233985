<template>
  <q-dialog ref="dialog" persistent>
    <div class="q-dialog-plugin RemovePositionModal" v-if="ready">
      <p class="p2">Är du säker på att du vill ta bort hela positionen?</p>
      <q-btn label="Ta bort" :loading="loading" v-bind="$defs.btn" @click="removeTarget" />
      <q-btn flat class="cancelBtn" @click="hide" label="Avbryt" />
    </div>
  </q-dialog>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { DialogComponent, FormComponent } from '@shared/mixins';
import { InstructionParty, Instruction } from '@shared/web/models';
import { deletePosition } from '@shared/web/models/position/positionService';
import { saveInstruction, removeDestination } from '@shared/web/models/instruction/instructionService';

@Component({
  components: {},
  mixins: [DialogComponent, FormComponent],
})
export default class RemovePositionModal extends Vue {
  @Prop() value;
  @Prop() instruction?: Instruction;
  @Prop() shareRegister;

  get ready() {
    return this.value != null;
  }

  get target() {
    return this.value;
  }

  async removeTarget() {
    if (this.value instanceof InstructionParty) {
      removeDestination(this.instruction, this.target);
      await saveInstruction(this.instruction);
    } else {
      await deletePosition(this.target, this.shareRegister);
    }
    this.hide();
  }
}
</script>
<style lang="scss">
.RemovePositionModal {
  width: 347px;
  background-color: white;
  padding: 20px 20px 5px;
  .q-btn {
    font-size: 14px;
    width: 100%;
    height: 40px;
    line-height: 30px;
    .q-btn__wrapper {
      padding: 0;
    }
  }
  .cancelBtn {
    margin-top: 10px;
    font-size: 14px;
    color: $primary;
  }
  .p2 {
    padding: 12px 0 24px;
    font-size: 15px;
    height: 90px;
  }
}
</style>
