<template>
  <div class="GeneralMeetingTable">
    <div>
      <q-markup-table flat class="fixed-thead-table">
        <thead>
          <tr>
            <th class="text-left">
              Ägare <br />
              Person-/Orgnr.
            </th>
            <th class="text-right">
              Anmäld till<br />
              bolagsstämma
            </th>
            <th class="text-right">Deltar</th>
            <th class="text-right">Närvarande röster</th>
            <th class="text-right">Andel av <br />närvarande =<br />Röstlängd</th>
            <th class="text-right" :key="instrument.name" v-for="instrument in shareRegister.instruments">
              {{ instrument.name }}
            </th>
            <th class="text-right">Totalt Antal</th>
            <th class="text-right">Kapitalandel</th>
            <th class="text-right">Antal röster</th>
            <th class="text-right">Röstandel</th>
          </tr>
        </thead>
        <tbody>
          <tr :key="i" v-for="(record, i) in owners">
            <td class="text-left">
              <span>{{ record.owner.viewName }}</span> <br />
              <span class="nationalid-small">{{ formatNationalId(record.owner) }}</span>
            </td>
            <td class="text-center">
              <q-checkbox name="signed" class="test-signed-checkbox" v-model="record.participation.signed" />
            </td>
            <td class="text-right">
              <q-checkbox
                name="participates"
                class="test-participates-checkbox"
                v-model="record.participation.participates"
              />
            </td>
            <td class="text-right">{{ formatNumber(record.participatingVotes) }}</td>
            <td class="text-right test-participating-vote-shares">
              {{ formatPercent(record.participatingVoteShare) }}
            </td>
            <td class="text-right" :key="instrument.name" v-for="instrument in shareRegister.instruments">
              {{ formatNumber(getInstrumentQuantity(instrument.name, record)) }}
            </td>
            <td class="text-right">{{ formatNumber(record.totalQuantity) }}</td>
            <td class="text-right">{{ formatPercent(record.capitalShare) }}</td>
            <td class="text-right">{{ formatNumber(record.votes) }}</td>
            <td class="text-right">{{ formatPercent(record.voteShare) }}</td>
          </tr>
        </tbody>
      </q-markup-table>
    </div>
    <aBtnLink class="test-clear" @click="clearGeneralMeetingParticipation">Rensa</aBtnLink>
  </div>
</template>
<script lang="ts">
import { ShareRegister, GeneralMeetingParticipation } from '@/models';
import PositionRow from '@shared/views/shareRegister/PositionRow.vue';
import InstrumentRow from '@shared/views/shareRegister/InstrumentRow.vue';
import { OwnerRegisterRecord, getGeneralMeetingRecords } from '@shared/shareRegister/utils';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import aBtnLink from '@shared/components/ABtnLink.vue';

import { FixedTheadTable } from '@/mixins';

const votingPowerSorter = (a: OwnerRegisterRecord, b: OwnerRegisterRecord): number => {
  return b.voteShare - a.voteShare;
};

@Component({
  components: { PositionRow, InstrumentRow, aBtnLink },
  mixins: [FixedTheadTable],
})
export default class GeneralMeetingTable extends Vue {
  @Prop() shareRegister: ShareRegister;
  @Prop() generalMeetingParticipation: GeneralMeetingParticipation;

  getInstrumentQuantity(name, record) {
    return record.instruments.find(instrument => instrument.name === name)?.quantity || 0;
  }

  get owners() {
    return getGeneralMeetingRecords(this.shareRegister, this.generalMeetingParticipation).sort(votingPowerSorter);
  }

  clearGeneralMeetingParticipation() {
    this.generalMeetingParticipation.owners.forEach(owner => {
      owner.signed = false;
      owner.participates = false;
    });
  }
}
</script>
<style lang="scss">
.GeneralMeetingTable {
  margin-top: 20px;
  width: 100%;
}
</style>
