<template>
  <div class="section">
    <div class="row justify-start q-gutter-x-lg">
      <div class="col-3">
        <h5>Befintlig eller ny ägare</h5>
        <div class="column radiobtn">
          <q-radio
            v-model="createNewOwner"
            @update:modelValue="toggleCreateNew"
            :val="false"
            label="Befintlig"
            :disable="readonly"
          />
          <q-radio
            v-model="createNewOwner"
            @update:modelValue="toggleCreateNew"
            :val="true"
            label="Ny"
            :disable="readonly"
          />
        </div>
      </div>
      <div v-if="!createNewOwner" class="col-3">
        <a-select
          v-model="entity"
          :options="existingForeignOwners"
          map-options
          option-label="viewName"
          :nullOption="false"
          v-bind="$defs.input"
          :readonly="readonly"
          class="test-list-foreign-owner"
        />
      </div>
      <div v-else class="col-3">
        <h5>Typ</h5>
        <div class="column radiobtn" v-if="entity">
          <q-radio
            v-model="entity.isPerson"
            :val="true"
            label="Privatperson"
            :readonly="readonly"
            @click="setEntity(true)"
          />
          <q-radio
            v-model="entity.isPerson"
            :val="false"
            label="Bolag"
            :readonly="readonly"
            @click="setEntity(false)"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
// @ts-nocheck @TODO kolla q-radio v-model
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { Entity, Position } from '@shared/web/models';
import { fetchEntityOwners } from '@shared/web/entity/entityService';
import { ShareRegister } from '@shared/models/modelTypes';

@Component({})
export default class ForeignOwnerStatus extends Vue {
  @Prop() position: Position;
  @Prop() shareRegister: ShareRegister;
  @Prop() modelValue: Entity;
  @Prop() readonly: boolean;

  createNewOwner = false;
  existingForeignOwners;

  async created() {
    this.existingForeignOwners = await this.getExistingForeignOwners();
    if (this.modelValue == null && this.existingForeignOwners.length === 0) {
      this.createNewOwner = true;
      this.createOwner();
    }
  }

  get entity() {
    return this.modelValue;
  }
  set entity(val) {
    this.$emit('update:modelValue', val);
  }

  createOwner() {
    this.setEntity(true);
  }

  toggleCreateNew(createNewOwner) {
    if (createNewOwner) {
      this.createOwner();
    }
  }

  setEntity(isPerson: boolean) {
    this.entity = Entity.newModel({ person: {}, legalEntity: {}, isPerson });
  }
  async getExistingForeignOwners() {
    const owners = await fetchEntityOwners(this.shareRegister.entity);
    return owners.filter(owner => !owner.isSE);
  }
}
</script>
