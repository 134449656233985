import { Model } from '../base';
import { ICurrencies, IInstrumentValuation } from '@shared/models/modelTypes';
import { ValuationMethod } from '@shared/models/types';
import { Instrument } from '@shared/web/models';
import { store } from '@shared/web/store';

export default class InstrumentValuation extends Model implements IInstrumentValuation {
  instrument: Instrument;
  price: number;
  date: Date = new Date();
  method: ValuationMethod;
  quarter: string;
  comment?: string;
  commentTitle?: string;
  companyValue?: number;

  static define = () => ({
    date: {
      type: 'date',
    },
    instrument: {
      model: Instrument,
    },
  });

  get priceSEK() {
    const rate = store.currencyRates?.getRate(this.instrument.currencyCode as ICurrencies);
    if (rate == null) {
      return null;
    }
    return this.price * rate;
  }

  get pricePercent() {
    if (this.instrument.isValueDistribution && this.price != null) {
      return this.price * 100;
    } else {
      return this.price;
    }
  }

  set pricePercent(value) {
    if (this.instrument.isValueDistribution) {
      this.price = value / 100;
    } else {
      this.price = value;
    }
  }
}
