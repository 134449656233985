import { Entity, IssuerInfo } from '@shared/web/models';

import { modelHandler } from '../base';
import axios from 'axios';
const baseUrl = '/webapi/issuer-info';
const { saveModel } = modelHandler(IssuerInfo, baseUrl);

export async function findIssuerInfo(entity: Entity) {
  const { data } = await axios.get(baseUrl + '/entity/' + entity.id);

  return IssuerInfo.newModel(data, true).copy();
}

export async function saveIssuerInfo(info: IssuerInfo): Promise<IssuerInfo> {
  info.contacts = info.contacts.filter(contact => contact.entity.id != null);
  const { model } = await saveModel(info);

  return model;
}
