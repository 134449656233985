<template>
  <q-input
    class="date-input"
    :modelValue="date"
    @change="textInput"
    v-bind="onlyProps($attrs)"
    :placeholder="placeholder"
    :hint="hint"
    :readonly="readonly"
    :rules="rules"
    ref="input"
  >
    <template v-slot:append>
      <svg v-if="!readonly">
        <use xlink:href="/svg-icons.svg#icon-calendar"></use>
      </svg>
      <q-menu ref="qDateProxy" transition-show="scale" transition-hide="scale" :offset="[195, -165]" class="shadow-1">
        <q-date v-model="date" minimal mask="YYYY-MM-DD" first-day-of-week="1" flat :options="options"> </q-date>
      </q-menu>
    </template>
  </q-input>
</template>
<script lang="ts">
import { format } from 'date-fns';
import { Component, Vue, Prop, Ref } from 'vue-facing-decorator';

function toValidDate(dateStr: string) {
  if (!dateStr) {
    return null;
  }
  const date = new Date(dateStr);

  if (isNaN(date.getTime())) {
    return null;
  }
  return date;
}

@Component({
   inheritAttrs: false,
   emits: ['update:modelValue', 'change']
  }
)
export default class InputDate extends Vue {
  @Prop() modelValue: Date;
  @Prop({ default: () => false }) readonly: boolean;
  @Prop() hint: string;
  @Prop() placeholder: string;
  @Prop() rules;
  @Prop() options;
  @Ref() readonly qDateProxy;
  @Ref() readonly input;
  textInput(dateStr) {
    this.date = dateStr;
  }

  get date(): string {
    return this.modelValue && format(this.modelValue, 'yyyy-MM-dd');
  }

  set date(val: string) {
    const result = toValidDate(val);

    this.$emit('update:modelValue', result);
    this.$emit('change', result);
    this.qDateProxy?.hide();
  }

  validate() {
    return this.input.validate();
  }
}
</script>
<style lang="scss">
.date-input {
  svg {
    width: 18px;
    height: 26px;
    cursor: pointer;
  }
}
</style>
