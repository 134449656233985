<template>
  <q-page v-if="ready" class="CheckIssueWarrantPage">
    <h1>{{ instruction.processTitle }}</h1>
    <Stepper :pageType="instruction.type" :currentstep="3" :isDone="isDone" :urlId="instruction.id" />
    <q-separator />
    <div v-if="instruction && !instruction.isSupplement">
      <h4 class="table-header">Villkor</h4>
      <WarrantDetails
        v-if="instruction && instruction.isWarrant"
        :shareRegister="shareRegister"
        :instruction="instruction"
        @change="save"
        :entity="instruction.entity"
        :readonly="true"
      />
      <BondDetails
        v-if="instruction && instruction.isBond"
        :share-register="shareRegister"
        :instruction="instruction"
        :entity="instruction.entity"
        :readonly="true"
      />
      <DebentureDetails
        v-if="instruction && instruction.isDebenture"
        :share-register="shareRegister"
        :instruction="instruction"
        :entity="instruction.entity"
        :readonly="true"
      />
      <ConvertibleDetails
        v-if="instruction && instruction.isConvertible"
        :instruction="instruction"
        :entity="instruction.entity"
        :readonly="true"
      />
      <ShareholderContributionDetails
        v-if="instruction && instruction.isShareholderContribution"
        :instruction="instruction"
        :readonly="true"
        :destinationsAmount="instruction.corporateEvent.rightsData.nominalAmountTotal"
      />
    </div>
    <h4 class="table-header">Deltagare</h4>
    <DestinationsTableCard
      :shareRegister="shareRegister"
      v-if="shareRegister"
      :instruction="instruction"
      :validator="validator"
      :readonly="true"
    />
    <q-btn v-bind="$defs.btn" type="button" class="step-btn" :disable="!isDone || !canEdit" @click="proceed"
      >Gå vidare</q-btn
    >
    <a-btn-link v-on:click="goBack" class="arrow icon-left">Gå tillbaka</a-btn-link>
  </q-page>
</template>
<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator';
import { Instruction, InstructionState, ShareRegister } from '@/models';
import Stepper from '@/components/Stepper.vue';
import WarrantDetails from './../issueWarrant/WarrantDetails.vue';
import DestinationsTableCard from './../issueWarrant/DestinationsTableCard.vue';
import { findInstruction, makeInstructionValidator, saveInstruction } from '@/models/instruction/instructionService';
import { findEntityWithRouteParams } from '@shared/web/entity/entityService';
import ConvertibleDetails from '@shared/views/convertible/ConvertibleDetails.vue';
import BondDetails from '@shared/views/bond/BondsDetails.vue';
import { store } from '@shared/web/store';
import { isToNewPage } from '@/utils';
import DebentureDetails from '@shared/views/debenture/DebentureDetails.vue';
import ShareholderContributionDetails from '@shared/views/shareholderContribution/ShareholderContributionDetails.vue';

@Component({
  components: {
    BondDetails,
    ConvertibleDetails,
    Stepper,
    WarrantDetails,
    DestinationsTableCard,
    DebentureDetails,
    ShareholderContributionDetails,
  },
})
export default class CheckInstructionPage extends Vue {
  @Prop({ default: true }) readonly: boolean;
  shareRegister: ShareRegister = null;
  instruction: Instruction = null;

  get canEdit() {
    return store.entityAdmin?.limitedAccess === false;
  }

  async created() {
    await this.loadData();
  }

  @Watch('$route')
  async loadData(to?, from?) {
    if (isToNewPage(to, from)) {
      return;
    }
    const { id } = this.$route.params;
    const { shareRegister, instruction } = await findInstruction(id);

    const entity = await findEntityWithRouteParams(this.$route.params);
    Object.assign(this, { shareRegister, instruction, entity });
  }

  async save() {
    await saveInstruction(this.instruction);
  }

  get isDone() {
    return true;
  }

  get validator() {
    return this.shareRegister && makeInstructionValidator(this.instruction, this.shareRegister);
  }

  get ready() {
    return this.shareRegister != null && this.instruction != null;
  }

  async proceed() {
    this.instruction.state = InstructionState.SUBMITTED;
    await saveInstruction(this.instruction);
    await this.$router.push({
      path: `${this.instruction.entity.baseUrl}/${this.instruction.uriLabel}/${this.instruction.id}/skickain`,
    });
  }
  async goBack() {
    await this.$router.push({
      path: `${this.instruction.entity.baseUrl}/${this.instruction.uriLabel}/${this.instruction.id}/dokument`,
    });
  }
}
</script>
